/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 */
export default function IconFavorite2(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 20;
  const height = props.width || 20;

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8484 9.95699L20 10.8185L19.1514 9.95686C17.6042 8.3851 15.491 7.5 13.2855 7.5C11.0801 7.5 8.96686 8.3851 7.41971 9.95686V9.95686C4.19343 13.2723 4.19343 18.5537 7.41971 21.8692L16.4075 30.9952C17.355 31.9578 18.6493 32.4999 20 32.4999C21.3508 32.4999 22.645 31.9578 23.5926 30.9952L32.5803 21.8693C35.8066 18.5539 35.8066 13.2724 32.5803 9.95701V9.95701C31.0331 8.3852 28.9199 7.50006 26.7144 7.50005C24.5089 7.50005 22.3956 8.38518 20.8484 9.95699Z"
        stroke={fill}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
