import styles from '@app/styles/components/ChapterChannel.module.sass';
import NavItem from './KeyNavItem';
import {useCallback, useEffect, useRef, useState} from 'react';
import moment from 'moment';
import 'moment/locale/es';

/**
 * Renders a ChapterChannel component based on the provided chapter data.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.chapter - The chapter data.
 * @param {boolean} props.chapter.isLive - Indicates if the chapter is live.
 * @param {string} props.chapter.title - The title of the chapter.
 * @param {number} props.chapter.chapterNumber - The chapter number.
 * @param {string} props.chapter.date - The date of the chapter.
 * @return {JSX.Element} The rendered ChapterChannel component.
 */
export default function ChapterChannel({
  title,
  image,
  date,
  name,
  dateStart,
  dateEnd,
  ...props
}) {
  const [advancedChapter, setAdvancedChapter] = useState(0);
  const [restInMinutes, setRestInMinutes] = useState(0);
  const [isCurrent, setIsCurrent] = useState(false);
  const updateAdvancedInterval = useRef(0);

  const getNewAdvanced = useCallback(() => {
    const dateEndMoment = moment.utc(dateEnd);
    const dateStartMoment = moment.utc(dateStart);
    const currentDateMoment = moment.utc();

    if (
      currentDateMoment.valueOf() > dateStartMoment.valueOf() &&
      currentDateMoment.valueOf() < dateEndMoment.valueOf()
    ) {
      setIsCurrent(true);
    } else if (currentDateMoment.valueOf() > dateEndMoment.valueOf()) {
      setIsCurrent(false);
    }

    let restMinutes = dateEndMoment.diff(currentDateMoment, 'minutes');

    const p =
      ((currentDateMoment.valueOf() - dateStartMoment.valueOf()) * 100) /
      (dateEndMoment.valueOf() - dateStartMoment.valueOf());

    if (p !== advancedChapter) {
      setAdvancedChapter(p);
      setRestInMinutes(restMinutes);
    }

    if (currentDateMoment.isAfter(dateEndMoment)) {
      setAdvancedChapter(100);
      setRestInMinutes(0);
      clearInterval(updateAdvancedInterval.current);
    }
  }, [dateEnd, dateStart]);

  useEffect(() => {
    getNewAdvanced();
    updateAdvancedInterval.current = setInterval(getNewAdvanced, 20 * 1000);
    return () => clearInterval(updateAdvancedInterval.current);
  }, []);

  return (
    <NavItem
      {...props}
      className={`${styles.chapter} ${isCurrent ? styles.active : ''}`}>
      <div className={styles.chapter_side}>
        {isCurrent ? (
          <div className={styles.capter_live_ctn}>
            <img
              src="images/live-circle.gif"
              width={30}
              height={30}
              style={{
                width: '30px',
                height: '30px',
              }}
              alt="en vivo"
            />
            <p>AHORA EN VIVO</p>
          </div>
        ) : (
          <div
            style={{
              backgroundImage: `url(${image})`,
            }}
            className={styles.chapter_image}
            alt="foto del capitulo"
          />
        )}
      </div>
      <div className={styles.chapter_info}>
        <h3 className={styles.chapter_title}>{title}</h3>
        <p className={styles.chapter_number}>{name}</p>
        {isCurrent ? (
          <div>
            <span className={styles.chapter_progresbar_label}>
              {restInMinutes} min restantes
            </span>
            <div className={styles.chapter_progressbar_ctn}>
              <div
                className={styles.chapter_progresbar}
                style={{width: `${advancedChapter}px`}}></div>
            </div>
          </div>
        ) : (
          <p className={styles.chapter_date}>{date}</p>
        )}
      </div>
    </NavItem>
  );
}
