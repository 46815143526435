import {API_URL} from '@app/constants';
import {fetchApi} from '@app/api/repository';
import platform from '@app/platform';

export async function playbackLog(formData, token) {
  try {
    const url = `${API_URL}/playback-log`;
    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      body: `start=${formData.start}&end=${formData.end}&duration=${formData.duration}&media_id=${formData.media_id}&app_id=${platform.bundleId}`,
    };
    const request = await fetchApi(url, params);
    if (typeof request?.text === 'function') {
      const response = await request.text();
      return response;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}
