import {
  getRemoteConfig,
  getValue,
  fetchAndActivate,
} from 'firebase/remote-config';
import {app} from '@app/libs/firebase';
import remote_config_defaults from '@app/remote_config_defaults.json';

export const remoteConfig = getRemoteConfig(app);

export function remoteConfigGetValue(key) {
  return getValue(remoteConfig, key);
}

remoteConfig.defaultConfig = remote_config_defaults;
remoteConfig.minimumFetchIntervalMillis = 1;

export function fetchRemoteConfig(callback) {
  fetchAndActivate(remoteConfig).finally(() => {
    if (typeof callback === 'function') {
      callback();
    }
  });
}
