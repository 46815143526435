const appRoutes = {
  home: () => '/',
  category: slug => `/category${slug ? '/' + slug : ''}`,
  search: slug => `/search${slug ? '/' + slug : ''}`,
  live: () => '/live',
  favorites: slug => `/favorites${slug ? '/' + slug : ''}`,
  schedule: slug => `/schedule${slug ? '/' + slug : ''}`,
  profile: slug => `/profile${slug ? '/' + slug : ''}`,
  login: () => '/login',
  channels: slug => `/channels${slug ? '/' + slug : ''}`,
};
export default appRoutes;
