import {imageSize} from '@app/utils/imageSize';
import {useRef} from 'react';
import {generateQuery, useGQL3} from './useGQL3';

export default function useFreeShows(payload) {
  const config = useRef(payload).current;

  function parseResponse(data) {
    return data?.data?.shows?.edges?.map(({node: show}) => show);
  }

  function getQuery() {
    return generateQuery(
      `query freeShows($imageType: ImageTypeEnum!, $imageSize: String!) {
        shows(first:${config.limit || 4}, availabilityType: ${
        config.availabilityType || 'UNKNOWN'
      }) {
          edges {
            node {
              id
              webSlug
              title
              images(imageType: $imageType) {
                url(size: $imageSize)
              }
            }
          }
        }
      }`,
      {
        imageType: 'LANDSCAPE',
        imageSize: imageSize(imageSize.type.show),
      },
    );
  }

  return useGQL3(parseResponse, getQuery);
}

useFreeShows.types = {
  UNKNOWN: 'UNKNOWN',
  FREE: 'FREE',
  PREMIUM: 'PREMIUM',
  FREEMIUM: 'FREEMIUM',
};
