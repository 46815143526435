import {fetchApi} from '@app/api/repository';
import {API_URL, ISGLOBAL} from '@app/constants';
import {getUserPremium, getUserToken} from '@app/stores/auth';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {generateQuery, useGQL3} from './useGQL3';

function isValidVastUrl(metadata) {
  if (metadata?.length > 0 && metadata[0]?.value) {
    return metadata[0]?.value;
  } else {
    return null;
  }
}

export async function getMediaToken(token, mediaId, mediaType) {
  try {
    const response = await fetch(`${API_URL}/mediaToken`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: token || (ISGLOBAL ? 'guest_int' : 'guest'),
      },
      body: `media_id=${mediaId}&media_type=${mediaType}`,
    });

    let result = {};

    if (response && typeof response.json === 'function') {
      result = await response.json();
    }

    if (response?.status >= 400 && response?.status <= 500) {
      result.error = `Error ${response.status}`;
      return Promise.resolve({});
    }

    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * @returns {Media}
 */
export function parseVODResponse(_data) {
  if (!_data) {
    return null;
  }
  const {chapter, season, show, category} = _data;

  let vastUrl =
    isValidVastUrl(chapter?.metadata) ||
    isValidVastUrl(season?.metadata) ||
    isValidVastUrl(show?.metadata) ||
    isValidVastUrl(category?.metadata) ||
    null;

  const duration = (chapter?.duration || 0) / 1000;

  const trackingTitle = ['VOD'];

  if (show?.title) {
    trackingTitle.push(show.title);
  }

  if (season?.title) {
    trackingTitle.push(season.title);
  }

  if (chapter?.title) {
    trackingTitle.push(chapter.title);
  }

  const result = {
    trackingTitle: trackingTitle.join(' - '),
    category: {
      id: category?.id,
      title: category?.title,
    },
    showData: {
      broadcast: show?.broadcast,
      id: show?.id,
      title: show?.title,
    },
    seasonData: {
      id: season?.id,
      title: season?.title,
    },
    chapterData: {
      free: chapter?.free,
      id: chapter?.id,
      title: chapter?.title,
      duration: duration,
      nextId: chapter?.next?.id,
    },
    videoData: {},
    vastUrl,
    duration,
  };

  return result;
}

/** @typedef {object} Media
 */

/**
 * @returns {Media}
 */
export function parseResponse(_data) {
  return _data
    ? parseVODResponse({
        category: _data?.data?.category,
        show: _data?.data?.show,
        season: _data?.data?.season,
        chapter: _data?.data?.chapter,
        media: null,
      })
    : null;
}

/**
 * @param {{
 *   categoryId: string,
 *   showId: string,
 *   seasonId: string,
 *   chapterId: string,
 *   metadataType?: string,
 * }} payload
 */
function getQuery(payload) {
  return generateQuery(
    `query chapterMediaToken($categoryId: GlobalID!, $showId: GlobalID!, $seasonId: GlobalID!, $chapterId: GlobalID!, $metadataType: MetadataTypeEnum!) {
      category: node(id: $categoryId) {
        ... on Category {
          title
          id
          metadata(metadataType: $metadataType) {
            value
          }
        }
      }
      show: node(id: $showId) {
        ... on Show {
          title
          id
          broadcast
          metadata(metadataType: $metadataType) {
            value
          }
        }
      }
      season: node(id: $seasonId) {
        ... on Season {
          title
          id
          metadata(metadataType: $metadataType) {
            value
          }
        }
      }
      chapter: node(id: $chapterId) {
        ... on Chapter {
          free
          id
          title
          duration
          metadata(metadataType: $metadataType) {
            value
          }
        }
      }
    }`,
    {
      categoryId: '',
      showId: '',
      seasonId: '',
      chapterId: '',
      metadataType: 'VAST_ANDROID',
      ...payload,
    },
  );
}

function getQueryMediaId(payload) {
  return generateQuery(
    `query chapterMediaId($chapterId: GlobalID!, $mediaProvider: MediaProviderEnum!) {
      chapter: node(id: $chapterId) {
        ... on Chapter {
          media(provider: $mediaProvider) {
            mediaId
          }
        }
      }
    }`,
    {
      chapterId: '',
      mediaProvider: 'MEDIASTREAM',
      ...payload,
    },
  );
}

function parseResponseMediaId(_data) {
  return _data?.data?.chapter?.media[0] || null;
}

/**
 * @returns {{
 *   getData: function({
 *     showId: string,
 *     seasonId: string,
 *     chapterId: string,
 *   } | {
 *     videoData: {
 *      mediaId: string,
 *      mediaToken?: string,
 *      user_progress_seconds?: number,
 *      user_progress_percentage?: number
 *    }
 *   }): Promise<Media>,
 *   data: Media
 * }}
 */
export default function useChapterMediaToken() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const token = useSelector(getUserToken);
  const userPremium = useSelector(getUserPremium);

  const gql = useGQL3(parseResponse, getQuery);
  const gqlMediaId = useGQL3(parseResponseMediaId, getQueryMediaId);

  async function getData(payload) {
    setLoading(true);
    setError(null);

    if (!payload || !token) {
      setError(true);
      setLoading(false);
      return;
    }

    try {
      let response = {};
      let media = null;

      if (payload?.videoData?.mediaId) {
        response = payload;
      } else {
        media = await gqlMediaId.getData({
          chapterId: payload.chapterId,
        });
        response = await gql.getData(payload);
        response.chapterData.media = media;
        response.videoData.mediaId = media?.mediaId;
      }

      let access = false;

      if (userPremium) {
        access = true;
      } else if (ISGLOBAL) {
        access = false;
      } else {
        access = token && response?.chapterData?.free === true;
      }

      if (response?.videoData?.mediaId && access) {
        const mediaToken = await getMediaToken(
          token,
          response?.videoData?.mediaId,
          'vod',
        );

        if (mediaToken?.ok && mediaToken?.access_token) {
          response.videoData.mediaToken = mediaToken.access_token;
          response.videoData.user_progress_seconds =
            mediaToken.user_progress_seconds;
          response.videoData.user_progress_percentage =
            mediaToken.user_progress_percentage;
        }
      }
      setData(response);
      return Promise.resolve(response);
    } catch (e) {
      setError(e);
      return Promise.reject(e);
    } finally {
      setLoading(false);
    }
  }

  return {
    data,
    loading,
    getData,
    error,
  };
}

export async function getChapterMedia(payload) {
  try {
    const response = await fetchApi(getQuery(payload));
    const result = parseResponse(await response.json());
    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
}
