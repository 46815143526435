import styles from '@app/styles/components/HomeChannels24Hours.module.sass';
import useScrollDrag from '@app/hooks/useScrollDrag';
import HomeChannel24hoursCard from './HomeChannel24hoursCard';
import {useCallback, useEffect, useRef, useState} from 'react';
import useChannels from '@app/hooks/useChannels';
import moment from 'moment';
import {KEY_NAV, keyNavAttribute, keyNavSelectors} from './KeyNavItem';

/**
 * A React component that displays a section with live channels for 24 hours.
 *
 * @param {object} props - The component's props.
 * @param {string} props.id - The ID of the section.
 * @param {function} props.onClick - A callback function to handle click events.
 * @param {object} props.keynavscrolltoel - An object with key navigation properties.
 * @param {object} props.keynavup - An object with key navigation properties.
 * @param {object} props.keynavdown - An object with key navigation properties.
 * @return {JSX.Element} The JSX element representing the component.
 */
export default function HomeChannels24Hours(props) {
  const slider = useRef(null);
  useScrollDrag(slider);
  const channels = useChannels();
  const [chapters, setChapters] = useState([]);

  const updateCurrentShow = useCallback(() => {
    const now = moment.utc();
    const chaptersChannel = {};

    channels?.data?.forEach(channel => {
      if (channel.scheduleJob) {
        let currentChapter = channel.scheduleJob.find(chapter => {
          const start = moment.utc(chapter.dateStart);
          const end = moment.utc(chapter.dateEnd);
          if (now.isBetween(start, end)) {
            chapter.id = channel.id;
            chapter.title = channel.title;
            chapter.remainingTime = moment
              .utc(chapter.dateEnd)
              .diff(now, 'minutes');

            const p =
              ((now.valueOf() - start.valueOf()) * 100) /
              (end.valueOf() - start.valueOf());
            chapter.progress = p;
            return chapter;
          }
        });
        if (currentChapter?.name) {
          chaptersChannel[channel.id] = currentChapter;
        } else {
          currentChapter = channel.scheduleJob.find(chapter => {
            chapter.id = channel.id;
            chapter.title = channel.title;
            chapter.remainingTime = 0;
            chapter.progress = 100;
            return chapter;
          });
          if (currentChapter?.name) {
            chaptersChannel[channel.id] = currentChapter;
          }
        }
      }
    });

    setChapters(Object.values(chaptersChannel));
  }, [channels]);

  useEffect(() => {
    updateCurrentShow();
    let timer = setInterval(() => {
      updateCurrentShow();
    }, 20 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, [channels.data]);

  useEffect(() => {
    channels.getData();
    return () => {};
  }, []);

  return (
    <>
      {chapters?.length > 0 && (
        <section
          id={props.id}
          className={styles.main}
          style={{
            backgroundImage: "url('images/bg-channels-24-hours.png')",
          }}>
          <h2 className={styles.title}>
            Canales en vivo <span>las 24 horas</span>
          </h2>
          <div
            id="24hoursList"
            className={styles.list}
            ref={slider}
            {...keyNavAttribute(KEY_NAV.PARENT)}>
            {chapters?.map(item => (
              <HomeChannel24hoursCard
                key={item?.id}
                channel={item?.title}
                img={`https:${item?.images[0]}`}
                chapterNumber={item?.name}
                currentTime={item.remainingTime}
                progress={item.progress}
                title={item.showName}
                onClick={() => {
                  if (typeof props.onClick === 'function') {
                    props.onClick({
                      id: item?.id,
                    });
                  }
                }}
                keynavscrolltoel={props.keynavscrolltoel}
                keynavup={props.keynavup}
                keynavdown={props.keynavdown}
                keynavleft={keyNavSelectors()
                  .steps('previousSibling')
                  .querySelector(
                    `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#menu [${KEY_NAV.ITEM}]`,
                  )
                  .build()}
                keynavright={keyNavSelectors().steps('nextSibling').build()}
              />
            ))}
          </div>
        </section>
      )}
    </>
  );
}
