import {imageSize} from '@app/utils/imageSize';
import {generateQuery, useGQL3} from './useGQL3';

/**
 * @typedef {object} Schedule
 * @property {string} startAt
 * @property {string} endAt
 * @property {string} title
 * @property {string} slug
 * @property {string} id
 * @property {object[]} images
 * @property {string} images.url
 */

/**
 * @typedef {object} getQueryPayload
 * @property {string} startAt
 * @property {string} endAt
 * @property {string} imageSize
 * @property {string} imageType
 */

/**
 * @returns {{
 *   data: Schedule[],
 *   getData: function(getQueryPayload): Promise<Schedule[]>
 * }}
 */
export default function useLiveSchedule(config) {
  /**
   * @returns {Schedule[]}
   */
  function parseResponse(data) {
    const result = data?.data?.schedule?.edges?.map(({node: item}) => {
      return {
        startAt: item.startAt * 1000,
        endAt: item.endAt * 1000,
        title: item.title,
        slug: item.slug,
        id: item.id,
        images: item.images,
        description: item.description,
      };
    });

    return result;
  }
  /**
   * @param {getQueryPayload} payload
   */
  function getQuery(payload) {
    return generateQuery(
      `query liveSchedule($imageType: ImageTypeEnum!, $imageSize: String!, $startAt: Int!, $endAt: Int!) {
        schedule(startAt: $startAt, endAt: $endAt) {
          edges {
            node {
              id
              title
              startAt
              endAt
              description
              images(imageType: $imageType){
                url(size: $imageSize)
              }
            }
          }
        }
      }`,
      {
        imageSize: imageSize(imageSize.type.show),
        imageType: 'LANDSCAPE',
        ...payload,
      },
    );
  }
  return useGQL3(parseResponse, getQuery, config);
}
