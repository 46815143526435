import {getUserId, getUserPremium, getUserToken} from '@app/stores/auth';
import {useEffect, useRef, useState} from 'react';
import useLiveToken from '@app/hooks/useLiveToken';
import {useDispatch, useSelector} from 'react-redux';
import Loading from '@components/Loading';
import MediaPlayer from '@components/VideoPlayer';
import styles from '@app/styles/views/VideoLive.module.sass';
import VideoRestricted from '@components/VideoRestricted';
import useLiveSchedule from '@app/hooks/useSchedule';
import moment from 'moment';
import {
  addSchedules,
  getScheduleLive,
  getSchedules,
} from '@app/stores/schedule';
import {ISGLOBAL, LIVE_PLAYER_ID} from '@app/constants';
import {fetchRemoteConfig, remoteConfigGetValue} from '@app/libs/remoteconfig';

export default function VideoLive() {
  const live = useLiveToken();
  const schedule = useLiveSchedule();
  const isRendered = useRef(false);
  const userPremium = useSelector(getUserPremium);
  const userId = useSelector(getUserId);
  const [template, setTemplate] = useState(<></>);
  const [vastUrl, setVastUrl] = useState(null);
  const token = useSelector(getUserToken);
  const schedulesAll = useSelector(getSchedules);
  const dispatch = useDispatch();

  function getADURL(url, opt) {
    let config = {
      ...opt,
    };

    if (!url) {
      return url;
    }

    config.correlator = new Date().getTime();
    config.description_url = encodeURIComponent(
      'https://tvgo.americatv.com.pe',
    );

    let urlBases = url.split('?');
    let urlParams = (urlBases[1] || '').split('&');
    let params = [];

    urlParams.forEach(item => {
      let pars = item.split('=');
      let key = pars[0];
      let value = pars[1];

      if (config[key]) {
        value = config[key];
      }
      params.push(`${key}=${value}`);
    });

    let result = urlBases[0] + '?' + params.join('&');

    if (result.indexOf('description_url') === -1 && config?.description_url) {
      result += '&description_url=' + config.description_url;
    }
    if (result.indexOf('rdid') === -1 && config?.rdid) {
      result += '&rdid=' + config.rdid;
    }
    if (result.indexOf('is_lat') === -1 && config?.is_lat !== undefined) {
      result += '&is_lat=' + config.is_lat;
    }

    return result;
  }

  useEffect(() => {
    const m = live.data || {};
    let title = 'vivo';
    let show = null;

    if (schedulesAll?.length > 0) {
      show = getScheduleLive(schedulesAll);

      if (show && show?.title) {
        title = ['vivo', show.title].join(' - ');
      }
    }

    let access = false;

    if (userPremium) {
      access = true;
    } else if (ISGLOBAL) {
      access = false;
    } else {
      access = true;
    }

    if (m?.mediaToken && access) {
      setTemplate(
        <MediaPlayer
          mediaId={m?.mediaId}
          mediaToken={m?.mediaToken}
          title={title}
          isLive={true}
          vastUrl={userPremium ? null : vastUrl}
          playerId={LIVE_PLAYER_ID}
        />,
      );
    } else {
      setTemplate(<VideoRestricted isLive={true} />);
    }
  }, [token, userId, live.data, schedulesAll]);

  useEffect(() => {
    async function getData() {
      try {
        if (!schedulesAll || schedulesAll?.length === 0) {
          const now = moment().utcOffset(-5);
          const today = moment(now.format('YYYY-MM-DDT00:00')).valueOf() / 1000;
          const endToday =
            moment(now.format('YYYY-MM-DDT23:59')).valueOf() / 1000;
          await schedule
            .getData({
              startAt: today,
              endAt: endToday,
            })
            .then(response => {
              dispatch(
                addSchedules({
                  schedules: response,
                  cursor: endToday,
                }),
              );
            });
        }
      } catch (e) {
        console.log('getData.error', e);
      } finally {
        fetchRemoteConfig(() => {
          const rawVastUrl =
            remoteConfigGetValue('live_vast_tablet').asString();
          setVastUrl(getADURL(rawVastUrl));
          live.getData();
        });
      }
    }

    if (!isRendered.current) {
      isRendered.current = true;

      getData();
    }
  }, [userPremium, userId]);

  return (
    <div className={styles.main}>
      {live.loading && <Loading />}
      {!live.loading && template}
    </div>
  );
}
