import React from 'react';
import styles from '@app/styles/components/ButtonTab.module.sass';
import NavItem, {keyNavProps} from './KeyNavItem';

export default function ButtonTab({label, active, ...props}) {
  return (
    <NavItem
      {...keyNavProps(props)}
      className={`${styles.main} ${active ? styles.active : ''}`}
      onClick={props.onClick}>
      <span>{label}</span>
    </NavItem>
  );
}
