import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from '@app/styles/views/schedule.module.sass';
import ButtonTab from '@components/ButtonTab';
import {IconSun} from '@components/icons/IconSun';
import {IconMoon} from '@components/icons/IconMoon';
import ScheduleItem from '@components/ScheduleItem';
import IconChannels from '@components/icons/IconChannels';
import {
  KEY_NAV,
  keyNavAttribute,
  keyNavSelectors,
} from '@components/KeyNavItem';
import useLiveSchedule from '@app/hooks/useSchedule';
import moment from 'moment';
import Loading from '@components/Loading';
import appRoutes from '@app/appRoutes';
import {useNavigate} from 'react-router-dom';

const WEEK_DAYS = [
  'LUNES',
  'MARTES',
  'MIERCOLES',
  'JUEVES',
  'SABADO',
  'DOMINGO',
];

export default function Schedule() {
  const isRendered = useRef(false);
  const schedule = useLiveSchedule();
  const [programsAM, setProgramsAM] = useState([]);
  const [programsPM, setProgramsPM] = useState([]);
  const [weekDaySelectedIndex, setWeekDaySelectedIndex] = useState(-1);
  const [dateTitle, setDateTitle] = useState('');
  const [currentShowId, setCurrentShowId] = useState(null);
  const check_current_program_live = useRef(0);
  const navigate = useNavigate();

  /**
   * Parsea los programas de la respuesta y los divide en programas AM y PM.
   *
   * @param {Array<Object>} data - La respuesta a parsear.
   * @return {void}
   */
  const parsePrograms = useCallback(data => {
    const AM = [];
    const PM = [];

    data.forEach(item => {
      if (item.startAt) {
        if (moment(item.startAt).hour() < 12) {
          AM.push(item);
        } else {
          PM.push(item);
        }
      }
    });
    setProgramsAM(AM);
    setProgramsPM(PM);
  }, []);

  /**
   * Returns the date of the desired weekday by calculating the difference
   * between the target weekday index and the current weekday index.
   *
   * @param {number} targetIndex - The target weekday index (0 = Monday, 6 = Sunday).
   * @return {moment.Moment} The date of the desired weekday.
   */
  const getDateByWeekdayIndex = useCallback(targetIndex => {
    // Índice del día de la semana actual (0 = lunes, 6 = domingo)
    const currentDayIndex = moment().isoWeekday() - 1;

    // Calcula la diferencia de días
    const dayDifference = targetIndex - currentDayIndex;

    // Obtiene la fecha del día deseado
    const targetDate = moment().add(dayDifference, 'days');

    // Retorna la fecha en el formato deseado
    return targetDate;
  }, []);

  const getPrograms = useCallback(date => {
    setDateTitle(date.format('dddd, DD [de] MMMM [de] YYYY'));

    // Calcular el inicio de la semana (lunes)
    const startToday = date.clone().startOf('day').utcOffset(-5);

    // Calcular el fin de la semana (domingo)
    const endToday = date.clone().endOf('day').utcOffset(-5);

    schedule.getData({
      startAt: Math.floor(startToday.valueOf() / 1000),
      endAt: Math.floor(endToday.valueOf() / 1000),
    });
  }, []);

  const changeWeekDay = useCallback(index => {
    setWeekDaySelectedIndex(index);
    getPrograms(getDateByWeekdayIndex(index));
  }, []);

  const checkCurrentProgramLive = useCallback(() => {
    if (!schedule.data?.find) {
      return;
    }

    const now = moment().valueOf();
    const show = schedule.data.find(item => {
      return now >= item.startAt && now < item.endAt;
    });
    if (show?.id) {
      setCurrentShowId(show.id);
    } else {
      setCurrentShowId(null);
    }
  }, [schedule.data]);

  const goToLive = useCallback(() => {
    navigate(appRoutes.live());
  }, []);

  useEffect(() => {
    if (schedule.data) {
      parsePrograms(schedule.data);
      checkCurrentProgramLive();
    }
  }, [schedule.data]);

  useEffect(() => {
    if (!isRendered.current) {
      changeWeekDay(moment().isoWeekday() - 1);
      isRendered.current = true;
    }
    check_current_program_live.current = setInterval(
      checkCurrentProgramLive,
      60 * 1000,
    );

    return () => {
      clearInterval(check_current_program_live.current);
    };
  }, []);

  return (
    <div className={styles.main} {...keyNavAttribute(KEY_NAV.SCROLL_DOWN)}>
      <div className={styles.inner}>
        <h1 className={styles.title}>Programación</h1>

        <div
          id="schedule_tabs"
          className={styles.tabs}
          {...keyNavAttribute(KEY_NAV.PARENT)}>
          {WEEK_DAYS.map((day, index) => (
            <ButtonTab
              key={day}
              onClick={() => changeWeekDay(index)}
              label={day}
              keynavleft={keyNavSelectors()
                .onlyIndex0(
                  `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                  `#menu [${KEY_NAV.ITEM}]`,
                )
                .build()}
              keynavright={keyNavSelectors().steps('nextSibling').build()}
              keynavdown={keyNavSelectors()
                .querySelector('#schedule_list [state="active"]')
                .build()}
              active={weekDaySelectedIndex === index}
            />
          ))}
        </div>

        <span className={styles.mainDate}>{dateTitle}</span>

        {schedule.loading && (
          <Loading
            style={{
              position: 'relative',
              width: '50%',
              height: '300px',
            }}
          />
        )}

        {!schedule.loading && (
          <div id="schedule_list" className={styles.columns}>
            <div className={styles.column}>
              <p className={styles.column_title}>
                <IconSun />
                AM
              </p>
              {programsAM.map((item, index) => (
                <ScheduleItem
                  key={item.id + index}
                  keynavleft={keyNavSelectors()
                    .querySelector(
                      `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                      `#menu-main [${KEY_NAV.ITEM}]`,
                    )
                    .build()}
                  keynavright={'disabled'}
                  keynavup={keyNavSelectors()
                    .querySelector(
                      `#schedule_tabs [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    )
                    .build()}
                  active={item.id === currentShowId}
                  timeText={moment(item.startAt).format('HH:mm')}
                  title={item.title}
                  description={item.description}
                  onClick={goToLive}
                />
              ))}
            </div>

            <div className={styles.column}>
              <p className={styles.column_title}>
                <IconMoon />
                PM
              </p>
              {programsPM.map((item, index) => (
                <ScheduleItem
                  key={item.id + index}
                  keynavleft={keyNavSelectors()
                    .querySelector(
                      `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                      `#menu-main [${KEY_NAV.ITEM}]`,
                    )
                    .build()}
                  keynavright={'disabled'}
                  keynavup={keyNavSelectors()
                    .querySelector(
                      `#schedule_tabs [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    )
                    .build()}
                  active={item.id === currentShowId}
                  timeText={moment(item.startAt).format('HH:mm')}
                  title={item.title}
                  description={item.description}
                  onClick={goToLive}
                />
              ))}
            </div>
          </div>
        )}
        <p className={styles.footer}>
          *Para conocer la programación de otros canales vea a la opción
          <IconChannels />
          Canales
        </p>
      </div>
    </div>
  );
}
