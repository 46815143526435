import styles from '@app/styles/components/Episodethumb2.module.sass';
import NavItem, {keyNavProps} from './KeyNavItem';
import TagExclusive from './TagExclusive';
import TagFree from './TagFree';
import IconPlay from './icons/IconPlay';

/**
 * Episodethumb.
 *
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.slug
 * @param {string} props.image
 * @param {object} props.season
 * @param {string} props.season.slug
 * @param {string} props.season.title
 * @param {object} props.show
 * @param {string} props.show.slug
 * @param {string} props.show.title
 * @param {function} props.onFocus
 * @param {function} props.onClick
 * @param {string|string[]} props.keynavup
 * @param {string|string[]} props.keynavdown
 * @param {string|string[]} props.keynavleft
 * @param {string|string[]} props.keynavright
 * @param {boolean} showInfoInBackground - Show info in background
 */
export default function Episodethumb2(props) {
  return (
    <NavItem
      {...keyNavProps(props)}
      className={styles.wrap}
      onClick={props.onClick}
      onMouseEnter={e => {
        if (typeof props.onFocus === 'function') {
          props.onFocus(e);
        }
      }}>
      <div className={styles.main}>
        <div className={styles.inner}>
          <div
            className={styles.picture}
            style={{
              backgroundImage: `url(${props.image})`,
            }}
          />
          {props.free !== undefined && props.free === true && (
            <TagFree
              styles={{position: 'absolute', left: 0, top: 10, zIndex: 2}}
            />
          )}
          {props.free !== undefined && props.free === false && (
            <TagExclusive
              styles={{position: 'absolute', left: 0, top: 10, zIndex: 2}}
            />
          )}
          <div className={styles.secundary}>
            <p className={styles.date}>{props?.show?.title} »</p>
            <p className={styles.title}>{props?.title}</p>
            {props.showInfoInBackground && (
              <span className={styles.iconContent}>
                <span className={styles.icon}>
                  <IconPlay />
                </span>
                <span className={styles.iconLabel}>Reanudar</span>
              </span>
            )}
          </div>
        </div>
      </div>
    </NavItem>
  );
}
