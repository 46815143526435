import {generateQuery, useGQL3} from './useGQL3';

/**
 * @typedef {object} Chapter
 * @property {string} free
 * @property {string} slug
 * @property {string} title
 * @property {string} duration
 * @property {object[]} media
 * @property {string} media.mediaId
 * @property {object} metadataType
 * @property {string} metadataType.value
 */

/**
 * @returns {{
 *   data: Chapter[],
 *   getData: function(): Promise<Chapter[]>
 * }}
 */
export default function useNextChapter() {
  function parseResponse(data) {
    return data?.data?.chapter;
  }

  function getQuery(payload = {}) {
    return generateQuery(
      `query nextChapter($chapterId: GlobalID!, $mediaProvider: MediaProviderEnum!, $metadataType: MetadataTypeEnum!){
        chapter: node(id: $chapterId) {
          ... on Chapter {
            free
            id
            title
            duration
            media(provider: $mediaProvider) {
              mediaId
            }
            metadata(metadataType: $metadataType) {
              value
            }
          }
        }
      }`,
      {
        chapterId: '',
        mediaProvider: 'MEDIASTREAM',
        metadataType: 'VAST_ANDROID',
        ...payload,
      },
    );
  }

  return useGQL3(parseResponse, getQuery);
}

/**
 * @typedef {object} nextChapterSlugGetQueryPayload
 * @property {string?} showSlug
 * @property {string?} seasonSlug
 * @property {string?} chapterSlug
 * @property {string?} chaptersSort
 */

/**
 * @returns {{
 *   data: Chapter[],
 *   getData: function(nextChapterSlugGetQueryPayload): Promise<Chapter[]>
 * }}
 */
export function useNextChapterId() {
  /**
   * @returns {Chapter[]}
   */
  function parseResponse(data) {
    return data?.data?.chapter?.next?.id;
  }

  /**
   * @param {nextChapterSlugGetQueryPayload} payload
   */
  function getQuery(payload = {}) {
    return generateQuery(
      `query nextChapterId($chapterId: GlobalID!, $seasonId: GlobalID!, $sort: SortEnum!) {
        chapter: node(id: $chapterId) {
          ... on Chapter {
            next(seasonId: $seasonId, sort: $sort) {
              id
            }
          }
        }
      }`,
      {
        seasonId: '',
        chapterId: '',
        sort: 'SORT_ASC',
        ...payload,
      },
    );
  }
  return useGQL3(parseResponse, getQuery);
}
