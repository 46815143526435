import appRoutes from '@app/appRoutes';
import {ACTIONS} from '@app/constants';
import {trackEvent} from '@app/libs/metrics';
import useDetail from '@app/hooks/useDetail';
import useEpisodesBySeason from '@app/hooks/useEpisodesBySeason';
import useFavoriteShow from '@app/hooks/useFavorites';
import {getUserId, getUserToken} from '@app/stores/auth';
import styles from '@app/styles/views/Detail.module.sass';
import {getShowImageSource} from '@app/utils/getShowImageSource';
import Alert from '@components/Alert';
import Button from '@components/Button';
import ButtonFavorite from '@components/ButtonFavorite';
import EpisodethumbExtend from '@components/EpisodethumbExtend';
import {IconAd} from '@components/icons/IconAd';
import IconPlay from '@components/icons/IconPlay';
import Loading from '@components/Loading';
import NavItem, {
  focus,
  keyNavAttribute,
  keyNavSelectors,
  KEY_NAV,
} from '@components/KeyNavItem';
import {useCallback} from 'react';
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {useLayoutEffect} from 'react';
import useSeasonsList from '@app/hooks/useSeasonList';
import {getCategory} from '@app/stores/shows';

export default function Detail() {
  const detail = useDetail();
  const seasonsList = useSeasonsList();
  const episodes = useEpisodesBySeason();
  const favorites = useFavoriteShow();
  const isRendered = useRef(false);
  const sinceAfter = useRef(null);
  const endPaginate = useRef(false);
  const body = useRef(null);
  const lastSeasonId = useRef(null);
  const lastUserId = useRef(null);
  const userId = useSelector(getUserId);
  const navigate = useNavigate();
  const token = useSelector(getUserToken);
  const {category_id, show_id, seasonId} = useParams();
  const [seasonActive, setSeasonActive] = useState(null);
  const [theModalAlert, setTheModalAlert] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [chapters, setChapters] = useState(null);
  const ref = useRef();
  const isLayoutRendered = useRef();
  const isOpenedModal = useRef(false);
  const innerRef = useRef(null);
  const category = useSelector(state => getCategory(state, category_id));

  async function addShowToFavorites() {
    if (!token) {
      setTheModalAlert({
        title: (
          <>
            <IconAd />
            <br />
            <span>Me gusta</span>
          </>
        ),
        body: (
          <>
            <strong>
              Necesitas ingresar a tu cuenta para usar esta opción
            </strong>
            <br />
            <span>¿Deseas ingresar?</span>
          </>
        ),
        footer: (
          <>
            <Button
              theme={'secundary-tiny'}
              label={'Cancelar'}
              onClick={() => setTheModalAlert(null)}
              keynavright={keyNavSelectors().steps('nextSibling').build()}
            />
            <Button
              theme={'tiny'}
              label={'Ingresar'}
              onClick={() => {
                navigate(appRoutes.login() + '?isSuccessBack=true');
              }}
              keynavleft={keyNavSelectors().steps('nextSibling').build()}
            />
          </>
        ),
      });
    } else {
      try {
        let response;

        if (favorites.isFavorite) {
          response = await favorites.removeFavorite({
            showId: show_id,
            token: token,
          });
          if (!response?.ok) {
            setTheModalAlert({
              title: 'Me gusta',
              description: 'No se pudo quitar este show de tus favoritos',
              actionDoneText: 'Aceptar',
              actionDone: () => {
                setTheModalAlert({});
              },
            });
          }
          console.log('favorites.removed', response);
        } else {
          response = await favorites.addFavorite({
            showId: show_id,
            token: token,
          });
        }

        setTimeout(() => {
          let current = ref.current?.querySelector('#detail-favorite-btn');
          if (!current) {
            current = ref.current?.querySelector(
              `[${KEY_NAV.ITEM_LAST_FOCUS}]`,
            );
          }
          if (!current) {
            current = ref.current?.querySelector(`[${KEY_NAV.ITEM}]`);
          }
          if (current) {
            focus(current);
          }
        }, 250);
      } catch (e) {
        console.log('favorites.error', e);
      }
    }
  }

  function onClickEpisodeHandler(episode) {
    navigate(
      appRoutes.category(
        `${category_id}/${show_id}/${seasonActive?.id}/${episode?.id}`,
      ),
    );
  }

  const getEpisodes = useCallback(
    activePaginate => {
      if (!seasonActive?.id) {
        return;
      }

      if (lastSeasonId.current !== seasonActive?.id) {
        sinceAfter.current = '';
        endPaginate.current = false;
        lastSeasonId.current = seasonActive?.id;
        setChapters([]);
      }

      setLoading(true);

      const getDataPayload = {
        showId: show_id,
        seasonId: seasonActive?.id,
        chapterFirst: 10,
        chapterAfter: sinceAfter.current,
        chaptersSort: detail?.data?.broadcast ? 'SORT_DESC' : 'SORT_ASC',
      };

      episodes
        .getData(getDataPayload)
        .then(response => {
          let c = response?.chapters;
          let cursor = response?.cursor;
          sinceAfter.current = cursor;

          if (c?.length > 0) {
            if (activePaginate) {
              setChapters([...chapters, ...c]);
            } else {
              setChapters(c);
            }
          } else if (!activePaginate) {
            setChapters(null);
          }

          if (
            !cursor ||
            c.length < getDataPayload.chapterFirst ||
            c.length === 0
          ) {
            endPaginate.current = true;
          } else {
            endPaginate.current = false;
          }
        })
        .finally(() => setLoading(false));
    },
    [seasonActive, chapters],
  );

  const onScrollHandler = useCallback(
    e => {
      const scrollTop = e?.target?.scrollTop;
      const innerHeight = e.target?.clientHeight;
      const offsetHeight = innerRef.current?.clientHeight;
      if (chapters?.length > 0 && endPaginate.current === false) {
        if (innerHeight + scrollTop >= offsetHeight) {
          if (isLoading === false) {
            getEpisodes(true);
          }
        }
      }
    },
    [seasonActive, chapters],
  );

  useEffect(() => {
    if (seasonActive?.id) {
      getEpisodes();
    }
  }, [seasonActive]);

  useEffect(() => {
    if (seasonsList?.data?.length > 0) {
      setSeasonActive(seasonsList?.data[0]);
    }
  }, [seasonsList.data]);

  useEffect(() => {
    if (token && lastUserId.current !== userId) {
      lastUserId.current = userId;
      favorites.getFavorite({
        showId: show_id,
      });
    }
  }, [token]);

  useEffect(() => {
    if (!isRendered.current) {
      isRendered.current = true;
      detail.getData({
        showId: show_id,
        categoryId: category_id,
      });
    }
  }, []);

  useEffect(() => {
    if (detail.data) {
      seasonsList.getData({
        showId: show_id,
        seasonSort: detail?.data?.broadcast ? 'SORT_DESC' : 'SORT_ASC',
      });
    }
  }, [detail.data, show_id]);

  useEffect(() => {
    if (theModalAlert === null && isOpenedModal.current) {
      isOpenedModal.current = false;
      let current = ref.current?.querySelector(`[${KEY_NAV.ITEM_LAST_FOCUS}]`);
      if (current) {
        focus(current);
      }
    } else if (theModalAlert) {
      isOpenedModal.current = true;
    }
  }, [theModalAlert]);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (!isLayoutRendered.current) {
        let current = ref.current?.querySelector(`[${KEY_NAV.ITEM}]`);
        if (current) {
          isLayoutRendered.current = true;
          focus(current);
        }
      }
    }, 500);
  });

  useEffect(() => {
    let localSeason = {};
    let tempSeason = {};

    if (seasonsList.data?.length > 0) {
      if (seasonsList.data && seasonsList.data.length > 0) {
        localSeason = seasonsList.data[0];

        if (seasonId) {
          tempSeason = seasonsList.data.find(s => {
            return s?.id === seasonId;
          });

          if (tempSeason) {
            localSeason = tempSeason;
          }
        }
      }
    }
  }, [seasonsList.data]);

  return (
    <div
      ref={ref}
      className={styles.main + ' hide_scrollbars'}
      onScroll={onScrollHandler}
      {...keyNavAttribute(KEY_NAV.SCROLL_DOWN)}>
      <div ref={innerRef}>
        {theModalAlert && (
          <Alert
            title={theModalAlert.title}
            body={theModalAlert.body}
            footer={theModalAlert.footer}
          />
        )}
        {detail.loading && (
          <Loading
            fixed={true}
            style={{zIndex: 3, backgroundColor: '#26212e'}}
          />
        )}
        {!detail.loading && (
          <>
            <div className={styles.background}>
              <div className={styles.backgroundShadow} />
              <div
                className={styles.backgroundImage}
                style={{
                  backgroundImage: `url(${getShowImageSource(
                    detail.data?.images,
                  )})`,
                }}
              />
            </div>
            <div ref={body} className={styles.inner}>
              <div className={styles.logo_content}>
                <img
                  onLoad={e => {
                    e.target.style.opacity = 1;
                  }}
                  className={styles.logo}
                  src={detail.data?.logo || 'images/tvgo-invertido.png'}
                  style={{opacity: 0}}
                  alt="logo"
                />
              </div>
              <div className={styles.description}>
                {detail.data?.description}
              </div>
              <div className={styles.meta}>
                <span className={styles.meta_item}>{category?.title}</span>
                <span className={styles.separator}>•</span>
                <span className={styles.meta_item}>
                  {seasonsList?.data?.length} temporada
                  {seasonsList?.data?.length !== 1 ? 's' : ''}
                </span>
              </div>
              <div
                id={'options'}
                className={styles.options}
                {...keyNavAttribute(KEY_NAV.PARENT)}>
                <Button
                  label="VER AHORA"
                  theme={
                    chapters === null || chapters?.length === 0
                      ? 'disabled'
                      : ''
                  }
                  icon={<IconPlay width={10} height={10} />}
                  onClick={() => {
                    if (chapters?.length > 0 && chapters[0]) {
                      onClickEpisodeHandler(chapters[0]);
                    }
                  }}
                  keynavscrolltotop={'true'}
                  keynavleft={keyNavSelectors()
                    .querySelector(`#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`)
                    .build()}
                  keynavdown={keyNavSelectors()
                    .querySelector(`#seasons [${KEY_NAV.ITEM}]`)
                    .build()}
                />
                <ButtonFavorite
                  id={'detail-favorite-btn'}
                  loading={favorites.loading}
                  checked={favorites.isFavorite}
                  onClick={addShowToFavorites}
                  keynavscrolltotop={'true'}
                  keynavleft={keyNavSelectors()
                    .steps('previousSibling')
                    .querySelector(
                      `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                      `#menu [${KEY_NAV.ITEM}]`,
                    )
                    .build()}
                  keynavdown={keyNavSelectors()
                    .querySelector(`#seasons [${KEY_NAV.ITEM}]`)
                    .build()}
                />
              </div>
              <div className={styles.tabs}>
                <div className={styles.tab_header}>
                  <div className={styles.tab_title}>EPISODIOS</div>
                </div>
                <div className={styles.tab_content}>
                  <div
                    id={'seasons'}
                    className={styles.seasons}
                    {...keyNavAttribute(KEY_NAV.PARENT)}>
                    {!detail?.loading && seasonsList?.data?.length === 0 && (
                      <p>Lo sentimos. No hay capitulos que mostrar</p>
                    )}
                    {seasonsList?.data?.map(season => {
                      return (
                        <NavItem
                          key={season?.id}
                          keynavup={keyNavSelectors()
                            .onlyIndex0(
                              `#options [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                              `#options [${KEY_NAV.ITEM}]`,
                            )
                            .steps('previousSibling')
                            .build()}
                          keynavdown={keyNavSelectors()
                            .steps('nextSibling')
                            .build()}
                          keynavright={keyNavSelectors()
                            .querySelector(
                              `#episodes [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                              `#episodes [${KEY_NAV.ITEM}]`,
                            )
                            .build()}
                          keynavleft={keyNavSelectors()
                            .querySelector(
                              `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                              `#menu-main [${KEY_NAV.ITEM}]`,
                            )
                            .build()}
                          onClick={() => {
                            trackEvent({
                              action: ACTIONS.PAGE.seasonClicked,
                            });
                            setSeasonActive(season);
                          }}
                          className={
                            seasonActive?.id === season?.id
                              ? styles.season_selected
                              : styles.season
                          }>
                          <div className={styles.season_title}>
                            {season?.title}
                          </div>
                        </NavItem>
                      );
                    })}
                  </div>
                  <div
                    id={'episodes'}
                    className={styles.episodes}
                    {...keyNavAttribute(KEY_NAV.PARENT)}>
                    {!episodes.loading && episodes.error && (
                      <p>
                        Lo sentimos. No se pudo obtener la lista de capitulos
                        para esta temporada.
                      </p>
                    )}
                    {!episodes?.loading && chapters?.length === 0 && (
                      <p>Lo sentimos. No hay capitulos que mostrar</p>
                    )}
                    {chapters?.length > 0 &&
                      chapters?.map(episode => {
                        return (
                          <EpisodethumbExtend
                            key={episode?.id}
                            title={episode?.title}
                            id={episode?.id}
                            image={getShowImageSource(
                              episode?.images,
                              '',
                              token,
                            )}
                            season={{
                              id: seasonActive?.id,
                              title: seasonActive?.title,
                            }}
                            show={{
                              id: episode?.id,
                              title: episode?.title,
                            }}
                            free={episode?.free}
                            onClick={() => {
                              onClickEpisodeHandler(episode);
                            }}
                            keynavup={keyNavSelectors()
                              .onlyIndex0(
                                `#options [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                                `#options [${KEY_NAV.ITEM}]`,
                              )
                              .steps('previousSibling')
                              .build()}
                            keynavdown={keyNavSelectors()
                              .steps('nextSibling')
                              .build()}
                            keynavleft={keyNavSelectors()
                              .querySelector(
                                `#seasons [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                              )
                              .build()}
                          />
                        );
                      })}
                    {isLoading && (
                      <Loading
                        relative={true}
                        style={{
                          position: 'relative',
                          width: '50%',
                          height: '100px',
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
