/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 * @param {string} props.className
 */
export default function IconHome2(props) {
  const width = props.width || 40;
  const height = props.height || 40;

  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none">
      <path
        d="M12.5 28.4375H27.5M18.841 5.35195L5.84095 14.1434C5.31336 14.5002 5 15.0778 5 15.6934V32.147C5 33.7227 6.34315 35 8 35H32C33.6569 35 35 33.7227 35 32.147V15.6934C35 15.0778 34.6866 14.5002 34.159 14.1434L21.159 5.35195C20.4651 4.88268 19.5349 4.88268 18.841 5.35195Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
