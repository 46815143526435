export function IconMoon(props) {
  const fill = props.fill || '#B3C1FD';
  const width = props.width || 24;
  const height = props.height || 24;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M20.958 15.325C21.162 14.839 20.579 14.425 20.09 14.641C19.1126 15.07 18.0565 15.2907 16.989 15.289C12.804 15.289 9.412 11.965 9.412 7.864C9.41047 6.47934 9.80385 5.12297 10.546 3.954C10.83 3.506 10.489 2.886 9.969 3.018C5.96 4.041 3 7.613 3 11.862C3 16.909 7.175 21 12.326 21C16.226 21 19.566 18.655 20.958 15.325Z"
        fill={fill}
      />
      <path
        d="M15.611 3.10301C15.081 2.74901 14.449 3.38101 14.802 3.91101L15.432 4.85601C15.6876 5.23917 15.824 5.68943 15.824 6.15001C15.824 6.61058 15.6876 7.06084 15.432 7.44401L14.802 8.38901C14.449 8.91901 15.082 9.55101 15.612 9.19701L16.556 8.56701C16.9392 8.31144 17.3894 8.17506 17.85 8.17506C18.3106 8.17506 18.7608 8.31144 19.144 8.56701L20.089 9.19701C20.619 9.55101 21.251 8.91901 20.897 8.38901L20.267 7.44401C20.0114 7.06084 19.875 6.61058 19.875 6.15001C19.875 5.68943 20.0114 5.23917 20.267 4.85601L20.897 3.91101C21.251 3.38101 20.619 2.74901 20.088 3.10301L19.144 3.73301C18.7608 3.98857 18.3106 4.12496 17.85 4.12496C17.3894 4.12496 16.9392 3.98857 16.556 3.73301L15.611 3.10301Z"
        fill={fill}
      />
    </svg>
  );
}
