/**
 * @param {{ node: {url: string}}[]} payload
 * @param {string} resize una cadea tipo 320x360
 */
export function getShowImageSource(payload, resize = '', token) {
  let img = null;
  let uri = null;
  let progressUrl = null;
  let thumb = null;
  if (payload && payload.length > 0) {
    img = payload[0] || {};
    uri = img?.url;
    progressUrl = img?.progressUrl || '';
    thumb = token && progressUrl ? `${progressUrl}&access_token=${token}` : uri;
    if (thumb && resize) {
      thumb = thumb.replace(/(\d+)x(\d+)/gm, resize);
    }
    return thumb;
  }
  return 'default-image.png';
}
