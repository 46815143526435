import {fetchApi} from '@app/api/repository';
import {imageSize} from '@app/utils/imageSize';
import {generateQuery, useGQL} from './useGQL';

/**
 * @typedef {object} Chapter
 * @property {object} show
 * @property {string} show.slug
 * @property {string} show.title
 * @property {object[]} show.images
 * @property {string} show.images.url
 * @property {object} show.season
 * @property {string} show.season.slug
 * @property {string} show.season.title
 * @property {object} show.season.chapter
 * @property {string} show.season.chapter.slug
 * @property {string} show.season.chapter.title
 */

/**
 * @typedef {object} getQueryPayload
 * @property {string} showSlug
 * @property {string} seasonSlug
 * @property {string} chapterSlug
 * @property {string?} imageSize
 * @property {string?} imageType
 */

function parseResponse(data) {
  return {
    show: data?.show,
    season: data?.season,
    chapter: data?.chapter,
  };
}

/**
 * @param {getQueryPayload} payload
 * @returns {Chapter}
 */
function getQuery(payload = {}) {
  const config = {
    showWebSlug: '',
    seasonWebSlug: '',
    chapterWebSlug: '',
    imageSize: imageSize(imageSize.type.show),
    imageType: 'POSTER',
    metadataType: 'VAST_ANDROID',
    ...payload,
  };

  function genShowQuery() {
    if (config.showWebSlug) {
      return `show(webSlug: "${config.showWebSlug}") {
          title
          slug
          images(imageType: $imageType) {
            url(size: $imageSize)
      }
    }`;
    } else {
      return '';
    }
  }

  function genSeasonQuery() {
    if (config.seasonWebSlug) {
      return `season(webSlug: "${config.seasonWebSlug}") {
          slug
          title
    }`;
    } else {
      return '';
    }
  }

  function genChapterQuery() {
    if (config.chapterWebSlug) {
      return `chapter(webSlug: "${config.chapterWebSlug}") {
          free
          slug
          title
          duration
          images(imageType: $imageType){
            url(size: $imageSize)
          }
          metadata(metadataType: ${config.metadataType}) {
            value
          }
    }`;
    } else {
      return '';
    }
  }

  return generateQuery(
    `query chapterDeeplink($imageSize: String!, $imageType: ImageTypeEnum!) {
      ${genShowQuery()}
      ${genSeasonQuery()}
      ${genChapterQuery()}
    }`,
    {
      imageSize: config.imageSize,
      imageType: config.imageType,
    },
  );
}
/**
 * @returns {{
 *  data: Chapter,
 *  getData: function(getQueryPayload): Promise<Chapter>
 * }}
 */
export default function useChapterDeeplinkSlugs() {
  return useGQL(parseResponse, getQuery);
}

/**
 * @param {getQueryPayload} payload
 * @returns {Promise<Chapter>}
 */
export async function getChapterDeeplinkSlugs(payload) {
  try {
    const response = await fetchApi(getQuery(payload));
    const result = parseResponse(await response.json());
    return result;
  } catch (e) {
    return null;
  }
}

/**
 * @param {object} payload
 * @param {string} payload.categorySlug
 * @param {string} payload.metadataType?
 */
export async function getCategoryDeeplink(payload) {
  try {
    const response = await fetchApi(
      generateQuery(
        `query categoryDeeplink($categorySlug: String!, $metadataType: MetadataTypeEnum) {
          category(webSlug: $categorySlug) {
                title
                slug
                render
                webSlug
                metadata(metadataType: $metadataType) {
                  value
                }
          }
        }`,
        {
          categorySlug: '',
          metadataType: 'VAST_ANDROID',
          ...payload,
        },
      ),
    );
    const result = await response.json();
    return Promise.resolve(result?.data?.categories?.edges[0]?.node);
  } catch (e) {
    return Promise.reject(e);
  }
}
