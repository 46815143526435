import appRoutes from '@app/appRoutes';
import {API_URL} from '@app/constants';
import {
  getCategoryDeeplink,
  getChapterDeeplinkSlugs,
} from '@app/hooks/useChapterDeeplink';

const TVGO_DOMAIN = 'tvgo.americatv.com.pe';
const VTVGO_DOMAIN = 'v.tvgo.pe';

export function splitUrl(url) {
  if (!url) {
    return;
  }

  url = url.replace(/\?.*$/, '');

  return url
    .replace('tvgo://', '')
    .replace('http://', '')
    .replace('https://', '')
    .split('/');
}

export async function getResolveDeeplink(url) {
  try {
    const response = await fetch(`${API_URL}/deeplink?url=${url}`);
    const result = await response.json();
    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
}

async function resolveDeeplink(url, matrix) {
  try {
    const _slugs = await getResolveDeeplink(url);

    if (_slugs?.show) {
      const deeplinkData = await getChapterDeeplinkSlugs({
        showWebSlug: _slugs?.show || '',
        seasonWebSlug: _slugs?.season || '',
        chapterWebSlug: _slugs?.chapter || '',
      });

      const _show = deeplinkData?.show;
      const _season = deeplinkData?.season;
      const _chapter = deeplinkData?.chapter;

      const _category = await getCategoryDeeplink({
        categorySlug: _show?.categorySlug || matrix[1],
      });

      return {
        _category,
        _show,
        _season,
        _chapter,
      };
    } else {
      return {};
    }
  } catch (e) {
    console.log('deeplinkProcess.getResolveDeeplink.error', e);
    return {};
  }
}

async function process(url, matrix, resolve, reject) {
  try {
    if (matrix[1] === '') {
      resolve({
        url: appRoutes.home(),
      });
    } else if (matrix[1] === deeplinkProcess.VIDEOLIVE) {
      resolve({
        url: appRoutes.live(),
      });
    } else if (
      matrix[1].indexOf(deeplinkProcess.SEARCH) !== -1 ||
      matrix[1].indexOf(deeplinkProcess.EXPLORER) !== -1 ||
      matrix[1].indexOf(deeplinkProcess.SECTIONS) !== -1
    ) {
      resolve({
        url: appRoutes.search(matrix[2] || ''),
      });
    } else if (matrix[1].indexOf(deeplinkProcess.FAVORITES) !== -1) {
      resolve({
        url: appRoutes.favorites(),
      });
    } else if (matrix[1].indexOf(deeplinkProcess.CHANNELS) !== -1) {
      resolve({
        url: appRoutes.channels(),
      });
    } else if (matrix.length > 1) {
      const deeplinkData = await resolveDeeplink(url, matrix);
      const _category = deeplinkData?._category;
      const _show = deeplinkData?._show;
      // const _season = deeplinkData?._season;
      const _chapter = deeplinkData?._chapter;

      if (_chapter?.slug) {
        // VOD
        resolve({
          url: appRoutes.category(_chapter.slug),
        });
      } else if (_show?.slug) {
        // SHOW
        resolve({
          url: appRoutes.category(_show.slug),
        });
      } else if (_category?.slug) {
        // category
        resolve({
          url: appRoutes.category(_category.slug),
        });
      }
    }
  } catch (e) {
    console.log('deeplink.process', e);
  }
}

export default function deeplinkProcess(url) {
  const matrix = splitUrl(url);

  return new Promise((resolve, reject) => {
    if (matrix) {
      if (
        matrix.length > 0 &&
        (matrix[0] === TVGO_DOMAIN || matrix[0] === VTVGO_DOMAIN)
      ) {
        process(url, matrix, resolve, reject);
      } else {
        reject();
      }
    } else {
      reject();
    }
  });
}

deeplinkProcess.VIDEOVOD = 'VIDEOVOD';
deeplinkProcess.VIDEOLIVE = 'en-vivo';
deeplinkProcess.SEARCH = 'buscar';
deeplinkProcess.EXPLORER = 'explorar';
deeplinkProcess.SECTIONS = 'secciones';
deeplinkProcess.USER = 'usuario';
deeplinkProcess.USERPROFILE = 'perfil';
deeplinkProcess.EDITPROFILE = 'editar-perfil';
deeplinkProcess.HELP = 'ayuda';
deeplinkProcess.WEBVIEW = 'WEBVIEW';
deeplinkProcess.CATEGORY = 'CATEGORY';
deeplinkProcess.SHOW = 'SHOW';
deeplinkProcess.FAVORITES = 'favoritos';
deeplinkProcess.BANNERS = 'banners';
deeplinkProcess.HOME = 'home';
deeplinkProcess.SUBSCRIPTION = 'subscription';
deeplinkProcess.CHANNELS = 'canalesenvivo';
