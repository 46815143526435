import {imageSize} from '@app/utils/imageSize';
import {generateQuery, useGQL3} from './useGQL3';

const bannerPlatforms = {
  ANDROID: 'android',
  IOS: 'ios',
  HMS: 'hms',
  WEB: 'web',
  ROKU: 'roku',
  ALL: '*',
};

/**
 * Hook to fetch and parse banners data for the home page.
 *
 * @return {object} An object containing the parsed banners data.
 */
export default function useBannersHome() {
  function parseResponse(data) {
    return data?.data?.banners?.edges?.map(({node: banner}) => banner);
  }

  function getQuery() {
    return generateQuery(
      `query bannersHome($imageType: ImageTypeEnum!, $imageSize: String!) {
        banners(first: 5) {
          edges {
            node {
              title
              url
              showName
              images(imageType: $imageType){
                url(size: $imageSize)
              }
            }
          }
        }
      }`,
      {
        imageType: 'LANDSCAPE',
        imageSize: imageSize(imageSize.type.bannerHome),
      },
      null,
      {
        platform: bannerPlatforms.WEB,
      },
    );
  }

  return useGQL3(parseResponse, getQuery);
}
