/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 * @param {string} props.className
 */
export default function IconCategories2(props) {
  const width = props.width || 40;
  const height = props.height || 40;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none">
      <path
        d="M31.6667 5C33.5076 5 35 6.47194 35 8.28766L35 13.8999C35 15.7156 33.5076 17.1875 31.6667 17.1875H26.6667C24.8257 17.1875 23.3333 15.7156 23.3333 13.8999L23.3333 8.28766C23.3333 6.47194 24.8257 5 26.6667 5L31.6667 5Z"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33333 5C6.49238 5 5 6.47194 5 8.28766L5.00001 13.8999C5.00001 15.7156 6.4924 17.1875 8.33335 17.1875H13.3333C15.1743 17.1875 16.6667 15.7156 16.6667 13.8999L16.6667 8.28766C16.6667 6.47194 15.1743 5 13.3333 5L8.33333 5Z"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.6667 22.8125C33.5076 22.8125 35 24.2845 35 26.1002V31.7123C35 33.5281 33.5076 35 31.6667 35H26.6667C24.8257 35 23.3333 33.5281 23.3333 31.7123L23.3333 26.1002C23.3333 24.2845 24.8257 22.8125 26.6667 22.8125H31.6667Z"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33334 22.8125C6.4924 22.8125 5.00001 24.2845 5.00001 26.1002L5.00002 31.7123C5.00002 33.5281 6.4924 35 8.33335 35H13.3333C15.1743 35 16.6667 33.5281 16.6667 31.7123L16.6667 26.1002C16.6667 24.2845 15.1743 22.8125 13.3333 22.8125H8.33334Z"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
