import {
  API_URL,
  API_URL_AUTH,
  ERROR_LOGIN,
  ERROR_TOKEN_INVALID,
  JWT_TOKEN_EXPIRED_ERROR,
} from '@app/constants';
import {ERROR_TOKEN_EXPIRED} from '@app/messages';
import jwtDecode from 'jwt-decode';

export const URL = `${API_URL}/gql`;

export const fetchConfig = {
  pause: false,
  stack: [],
  onUnauthorized: function () {
    return Promise.resolve(true);
  },
  validateToken: function () {
    return true;
  },
};

export async function validateJWT(token) {
  try {
    if (token === 'guest' || token === 'guest_int') {
      return {};
    }

    const jwt = jwtDecode(token);

    if (jwt.country && jwt.grant_type && jwt.iss && jwt.session && jwt.userid) {
      return {
        country: jwt.country,
        exp: jwt.exp,
        grant_type: jwt.grant_type,
        iss: jwt.iss,
        premium: jwt.premium,
        session: jwt.session,
        userid: jwt.userid,
        access_token: token,
        expires_in: jwt.exp,
      };
    } else {
      throw ERROR_TOKEN_INVALID;
    }
  } catch (e) {
    return {
      error: ERROR_LOGIN,
      stack: e,
    };
  }
}

let intentCount = 0;
let intentTimestamp = new Date().getTime();
let timeoutIdCleanIntentRefreshtoken = 0;

function cleanIntentRefreshtoken() {
  intentCount = 0;
  intentTimestamp = new Date().getTime();
}

async function intentRefreshToken(response, payload, url) {
  const _token = await fetchConfig.onUnauthorized();
  const now = new Date().getTime();
  const limitTime = 1000 + intentCount * 1000;

  if (intentCount > 0 && now - intentTimestamp < limitTime) {
    throw new Error(
      'Intentos de refresco en intervalos de tiempo no permitidos',
    );
  } else {
    intentTimestamp = now;
    intentCount++;
  }

  if (_token) {
    fetchApi(url, payload);
  } else {
    throw response?.status;
  }

  ++intentCount;
  clearTimeout(timeoutIdCleanIntentRefreshtoken);
  timeoutIdCleanIntentRefreshtoken = setTimeout(30000, cleanIntentRefreshtoken);
}

/**
 * fetchApi.
 *
 * @param {string} url
 * @param {object} params
 * @param {string} params.body
 * @param {object} params.headers
 * @param {string} params.method
 * @param {number} params.signal
 */
export async function fetchApi(url, payload = {}) {
  try {
    let jwt = null;

    if (payload?.headers?.Authorization) {
      jwt = await validateJWT(payload.headers.Authorization);
    }

    if (
      jwt?.error === ERROR_LOGIN ||
      jwt?.stack?.name === ERROR_TOKEN_EXPIRED ||
      jwt?.stack?.name === JWT_TOKEN_EXPIRED_ERROR
    ) {
      const _token = await fetchConfig.onUnauthorized();
      if (_token) {
        fetchApi(url, payload);
      } else {
        throw jwt?.stack;
      }
    } else if (jwt?.stack) {
      throw jwt.stack;
    } else {
      const response = await fetch(url, payload);
      if (response.status >= 400 && response.status <= 403) {
        intentRefreshToken(response, payload, url);
      } else {
        return Promise.resolve(response);
      }
    }
  } catch (e) {
    intentRefreshToken(
      {
        status: 500,
      },
      payload,
      url,
    );
    //return Promise.reject(e);
  }
}

/**
 * fetchGraphQL.
 *
 * @param {string} query
 * @param {object} config
 * @param {object} config.headers
 * @param {object} config.signal
 */
export async function fetchGraphQL(query, config = {}) {
  return fetch(URL, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...config?.headers,
    },
    body: JSON.stringify({
      query: query,
      variables: {},
    }),
    signal: config.signal,
  }).then(response => {
    if (typeof response?.json === 'function') {
      return response.json();
    } else {
      return response;
    }
  });
}

export async function refreshToken(token) {
  const url = `${API_URL_AUTH}/oauth/refresh-token`;
  const payload = {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  return new Promise((resolve, reject) => {
    fetch(url, payload)
      .then(async response => {
        if (response.status === 200) {
          resolve(
            response
              .json()
              .then(data => {
                return Promise.resolve(data);
              })
              .catch(error => {
                return Promise.reject(error);
              }),
          );
        } else {
          reject();
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
