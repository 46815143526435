/**
 * @readonly
 * @enum {string}
 * @property {string} show
 * @property {string} chapter
 * @property {string} banner
 * @property {string} background
 */
const Types = {
  show: 'show',
  chapter: 'chapter',
  banner: 'banner',
  background: 'background',
  bannerHome: 'bannerHome',
};

/**
 * @param {Types} type
 * @return {string}
 */
export function imageSize(type) {
  let result = '300x168';

  if (type === Types.show) {
    result = '400x224';
  } else if (type === Types.chapter) {
    result = '400x224';
  } else if (type === Types.banner) {
    result = '1000x561';
  } else if (type === Types.bannerHome) {
    result = '0x440';
  }

  return result;
}

imageSize.type = Types;
