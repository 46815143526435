/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 */
export default function IconProfile2(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 28;
  const height = props.width || 28;

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none">
      <path
        d="M33.3337 33.3333L33.3337 32.0833C33.3337 28.1713 30.1623 25 26.2503 25L13.7503 25C9.83831 25 6.66699 28.1713 6.66699 32.0833L6.66699 33.3333"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="19.9997"
        cy="11.6667"
        rx="6.66667"
        ry="6.66667"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
