/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 * @param {string} props.className
 */

export default function IconLive2(props) {
  const width = props.width || 58;
  const height = props.height || 34;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 58 34"
      fill="none">
      <path
        d="M39.4462 7.38481H32.473L37.4021 2.34796C37.832 1.90873 37.832 1.19737 37.4021 0.758135C36.9723 0.318903 36.2761 0.318903 35.8463 0.758135L29.4757 7.26784L23.1052 0.758135C22.6753 0.318903 21.9792 0.318903 21.5493 0.758135C21.1195 1.19737 21.1195 1.90873 21.5493 2.34796L26.4785 7.38481H18.3769C16.2604 7.38481 14.541 9.14412 14.541 11.3045V24.4456C14.541 26.6084 16.2627 28.3653 18.3769 28.3653H39.4486C41.5651 28.3653 43.2845 26.606 43.2845 24.4456V11.3045C43.2845 9.14412 41.5627 7.38481 39.4462 7.38481ZM41.0838 24.4456C41.0838 25.3671 40.3503 26.119 39.4462 26.119H18.3746C17.4728 26.119 16.737 25.3694 16.737 24.4456V11.3045C16.737 10.383 17.4705 9.63109 18.3746 9.63109H39.4462C40.348 9.63109 41.0838 10.3807 41.0838 11.3045V24.4456Z"
        fill="white"
      />
      <path
        d="M37.4007 31.3232H20.4265C19.8191 31.3232 19.3262 31.8269 19.3262 32.4476C19.3262 33.0682 19.8191 33.5719 20.4265 33.5719H37.4007C38.0081 33.5719 38.501 33.0682 38.501 32.4476C38.4986 31.8269 38.0081 31.3232 37.4007 31.3232Z"
        fill="white"
      />
      <path
        d="M47.6814 25.7008C47.401 25.7008 47.1184 25.591 46.9034 25.3714C46.4736 24.9321 46.4736 24.2208 46.9034 23.7815C50.3165 20.2939 50.515 14.8226 47.345 11.5833C46.9151 11.1441 46.9151 10.4327 47.345 9.99349C47.7748 9.55425 48.471 9.55425 48.9008 9.99349C52.9282 14.1089 52.7297 21.0053 48.4593 25.369C48.2444 25.591 47.964 25.7008 47.6814 25.7008Z"
        fill="white"
      />
      <path
        d="M10.3171 25.6994C10.0368 25.6994 9.75415 25.5896 9.53922 25.37C5.26883 21.0063 5.07026 14.1075 9.0977 9.99446C9.52754 9.55523 10.2237 9.55523 10.6535 9.99446C11.0834 10.4337 11.0834 11.1451 10.6535 11.5843C7.48579 14.8236 7.68203 20.295 11.0951 23.7826C11.5249 24.2218 11.5249 24.9332 11.0951 25.3724C10.8801 25.5896 10.5975 25.6994 10.3171 25.6994Z"
        fill="white"
      />
      <path
        d="M53.7979 6.52278C53.3681 6.08355 52.6719 6.08355 52.2421 6.52278C51.8122 6.96202 51.8122 7.67338 52.2421 8.11262C54.6459 10.569 55.9074 13.9038 55.7906 17.5012C55.6738 21.1273 54.1787 24.5886 51.5763 27.2455C51.1464 27.6848 51.1464 28.3961 51.5763 28.8354C51.7912 29.055 52.0715 29.1648 52.3542 29.1648C52.6345 29.1648 52.9172 29.055 53.1321 28.8354C56.1294 25.7727 57.8557 21.7718 57.9912 17.5752C58.1267 13.35 56.6386 9.42554 53.7979 6.52278Z"
        fill="white"
      />
      <path
        d="M5.64552 29.1667C5.36519 29.1667 5.08252 29.0569 4.8676 28.8373C1.87038 25.7746 0.143994 21.7738 0.00850036 17.5772C-0.126994 13.352 1.36111 9.42511 4.20181 6.52474C4.63165 6.0855 5.32781 6.0855 5.75766 6.52474C6.1875 6.96397 6.1875 7.67534 5.75766 8.11457C3.3538 10.5709 2.09231 13.9058 2.20911 17.5032C2.32592 21.1292 3.82336 24.5906 6.42345 27.2475C6.85329 27.6867 6.85329 28.3981 6.42345 28.8373C6.20853 29.0569 5.92586 29.1667 5.64552 29.1667Z"
        fill="white"
      />
      <path
        d="M27.4782 13.3406L32.8203 16.9712C33.1244 17.1783 33.1244 17.6341 32.8203 17.8361L27.4782 21.4667C27.1386 21.6946 26.6875 21.4511 26.6875 21.0316V13.7704C26.6875 13.3613 27.1386 13.1127 27.4782 13.3406Z"
        fill="white"
      />
    </svg>
  );
}
