import styles from '@app/styles/components/MostRecentEpisodes.module.sass';
import Episodethumb2 from './Episodethumb2';
import {getUserToken} from '@app/stores/auth';
import {useSelector} from 'react-redux';
import {KEY_NAV, keyNavAttribute} from './KeyNavItem';

export default function MostRecentEpisodes(props) {
  const {data} = props;

  const token = useSelector(getUserToken);

  return (
    <div className={styles.main}>
      <p className={styles.MostRecentEpisodes_label}>{props.label}</p>
      <ul
        className={styles.MostRecentEpisodes}
        {...keyNavAttribute(KEY_NAV.PARENT)}>
        {data.map((item, index) => (
          <Episodethumb2
            key={item?.id}
            image={
              token && item?.images[0]?.progressUrl
                ? item?.images[0]?.progressUrl + '&access_token=' + token
                : item?.images[0]?.url
            }
            season={item?.season}
            show={{title: item?.showTitle}}
            slug={item?.id}
            title={item?.title}
            free={item?.free}
            showInfoInBackground={props.showInfoInBackground}
            keynavup={props.keynavup}
            keynavdown={props.keynavdown}
            keynavleft={props.keynavleft}
            keynavright={props.keynavright}
            onFocus={() => {
              if (typeof props.onFocusItem === 'function') {
                props.onFocusItem(item, index);
              }
            }}
            onClick={() => {
              if (typeof props.onClickItem === 'function') {
                props.onClickItem(item, index);
              }
            }}
          />
        ))}
      </ul>
    </div>
  );
}
