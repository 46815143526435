/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 * @param {string} props.className
 */

export default function IconChannels(props) {
  const width = props.width || 40;
  const height = props.height || 40;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none">
      <path
        d="M20.1758 14.7225H15.5925M26.4587 23.9782L34.2086 27.7955C35.012 28.2845 35.855 27.9964 35.8333 26.9764L35.779 13.4854C35.7104 12.3781 35.0569 12.076 34.0947 12.5877L26.4369 16.0679M8.75 30.8337H22.6758C24.7469 30.8337 26.4258 29.1755 26.4258 27.13L26.4587 22.3796L26.4258 12.8707C26.4258 10.8252 24.7469 9.16699 22.6758 9.16699H8.75C6.67893 9.16699 5 10.8252 5 12.8707V27.13C5 29.1755 6.67893 30.8337 8.75 30.8337Z"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
