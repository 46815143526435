import {API_URL} from '@app/constants';
import {getUserToken} from '@app/stores/auth';
import {imageSize} from '@app/utils/imageSize';
import {useState} from 'react';
import {useSelector} from 'react-redux';

/**
 * @param {Object} props
 * @param {number} props.limit
 * @returns {{ data: any, getData: () => Promise<void>, cursor: * }}
 */
export default function useHistory(props = {}) {
  const [data, setData] = useState(null);
  const [cursor, setCursor] = useState(null);
  const token = useSelector(getUserToken);

  async function getData() {
    try {
      const response = await fetch(
        `${API_URL}/history?imageType=landscape&imageSize=${imageSize(
          imageSize.type.chapter,
        )}&metadataType=${'vast_android'}&limit=${props.limit || 4}`,
        {
          method: 'GET',
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      if (typeof response?.json === 'function') {
        const result = await response.json();
        setData(result.data);
        setCursor(result.cursor);
        return result;
      }
    } catch (e) {
      console.log('useHistory.error', e);
    }
  }

  return {
    data,
    getData,
    cursor,
  };
}
