import styles from '@app/styles/components/ScheduleItem.module.sass';
import NavItem, {keyNavProps} from './KeyNavItem';

function Div({children, ...props}) {
  return <div {...props}>{children}</div>;
}

export default function ScheduleItem({
  timeText,
  title,
  description,
  active,
  ...props
}) {
  const Wrap = active ? NavItem : Div;

  return (
    <Wrap
      {...keyNavProps(props)}
      onClick={props?.onClick}
      state={active ? 'active' : undefined}
      className={styles.content}>
      <div className={styles.inner}>
        <div className={styles.side}>{timeText}</div>
        <div className={styles.main}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
          <img
            className={styles.btn}
            src={'images/btn-ver-ahora.png'}
            alt="Ver ahora"
          />
        </div>
      </div>
    </Wrap>
  );
}
