import {fetchApi} from '@app/api/repository';
import {API_URL} from '@app/constants';
import {useRef} from 'react';
import {useCallback, useState} from 'react';

/**
 * @typedef {Object} UseGQLReturn
 * @property {function} getData
 * @property {function(boolean)} setLoading
 * @property {function} setError
 * @property {function} data
 * @property {function} loading
 */

/**
 * Hook personalizado que filtra y maneja datos de programas (shows) utilizando el hook useGQL.
 * @param {function} parseResponse - Función para parsear la respuesta de la API.
 * @param {function} getQuery - Función para generar la consulta de GraphQL.
 * @param {object} config - Configuración del hook.
 * @returns {UseGQLReturn}
 */
export function useGQL3(
  parseResponse,
  getQuery,
  config = {
    loading: true,
  },
) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(
    config.loading !== undefined ? config.loading : true,
  );
  const inProcess = useRef(false);
  const controllerRef = useRef(null);

  const getData = useCallback(
    async payload => {
      if (inProcess.current === false) {
        inProcess.current = true;
        setLoading(true);
        setError(null);
        if (controllerRef.current) {
          controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        try {
          const response = await fetchApi(getQuery(payload), {
            signal: controllerRef?.current?.signal,
          });
          const result = parseResponse(await response.json());
          setData(result);
          return Promise.resolve(result);
        } catch (e) {
          setError(e);
          return Promise.reject(e);
        } finally {
          setLoading(false);
          inProcess.current = false;
        }
      }
    },
    [getQuery, parseResponse],
  );

  return {
    data,
    loading,
    setLoading,
    error,
    getData,
  };
}

/**
 * @returns {string}
 */
export function parseQueryString(query) {
  return encodeURIComponent(query.replace(/\n/g, ' ').replace(/\s+/g, ' '));
}

/**
 *  @description Genera la url para GQL concatenando query y variables
 * @param {string} query
 * @param {{[key:string]: any}} variables
 * @returns {string}
 */
export function generateQuery(query, variables, url, extraParams) {
  if (!url) {
    url = API_URL;
  }
  return (
    url +
    '/gql?' +
    (function (params) {
      let result = [];
      let p;
      for (p in params) {
        let value = params[p];
        if (typeof value?.map === 'function') {
          value.map(v => {
            result.push(p + '=' + v);
          });
        } else {
          result.push(p + '=' + value);
        }
      }
      return result.join('&');
    })({
      variables: encodeURIComponent(JSON.stringify(variables)),
      query: parseQueryString(query),
      ...(extraParams || {}),
    })
  );
}
