import styles from '@app/styles/components/BannersSlider.module.sass';
import NavItem, {KEY_NAV, keyNavAttribute, keyNavSelectors} from './KeyNavItem';
import {useState} from 'react';

/**
 * A reusable component for rendering a slider of banners.
 *
 * @param {string} id - The unique identifier for the banner slider.
 * @param {object[]} data - An array of banner objects containing url and images.
 * @param {string} keynavdown - The key navigation direction for moving down.
 * @param {string} keynavup - The key navigation direction for moving up.
 * @param {function} onClick - A callback function for handling banner clicks.
 * @return {JSX.Element} The JSX element representing the banner slider.
 */
export default function BannersSlider({
  id,
  data,
  keynavdown,
  keynavup,
  onClick,
}) {
  const [bannerFocusIndex, setBannerFocusIndex] = useState(0);

  return (
    <div id={id} className={styles.banner}>
      <div
        className={styles.banner_sliders}
        {...keyNavAttribute(KEY_NAV.PARENT)}>
        {data?.map((banner, index) => (
          <NavItem
            key={banner?.url + '_' + index}
            onClick={() => {
              if (typeof onClick === 'function') {
                onClick(banner?.url);
              }
            }}
            keynavleft={keyNavSelectors()
              .onlyIndex0(
                `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                `#menu [${KEY_NAV.ITEM}]`,
              )
              .steps('previousSibling')
              .build()}
            keynavright={keyNavSelectors().steps('nextSibling').build()}
            keynavdown={keynavdown || 'disabled'}
            keynavup={keynavup || 'disabled'}
            onFocus={() => setBannerFocusIndex(index)}
            className={styles.banner_item}>
            <div
              className={styles.banner_item_main}
              style={{
                backgroundImage: `url(${banner?.images[0]?.url})`,
              }}></div>
          </NavItem>
        ))}
      </div>
      {data?.length > 1 && (
        <div className={styles.banner_dots}>
          {data?.map((_, index) => (
            <div
              key={'dot_' + index}
              className={`${styles.banner_dot} ${
                index === bannerFocusIndex ? styles.banner_dot_focus : ''
              }`}
              data-index={index}></div>
          ))}
        </div>
      )}
    </div>
  );
}
