import {fetchApi} from '@app/api/repository';
import {API_URL_AUTH} from '@app/constants';
import {getUserToken} from '@app/stores/auth';
import {useState} from 'react';
import {useSelector} from 'react-redux';

export async function logoutApi(payload) {
  try {
    const url = `${API_URL_AUTH}/oauth/revoke`;
    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: payload.token,
      },
    };
    const response = await fetchApi(url, params);
    if (typeof response?.json === 'function') {
      const result = await response.json();
      return result;
    } else {
      return null;
    }
  } catch (error) {
    console.log('logoutUserApi', error);
    return null;
  }
}

export default function useProfile() {
  const token = useSelector(getUserToken);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  async function getData() {
    setLoading(true);
    try {
      const response = await fetchApi(`${API_URL_AUTH}/profile`, {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      });
      if (typeof response?.json === 'function') {
        const result = await response.json();
        setData(result);
        return Promise.resolve(result);
      } else {
        return Promise.reject(null);
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      setLoading(false);
    }
  }

  return {
    loading,
    data,
    getData,
  };
}
