const initialState = {
  isHideMenu: false,
};

export const IS_HIDE_MENU = 'IS_HIDE_MENU';
export const IS_OPEN_MENU = 'IS_OPEN_MENU';
export const IS_BLOCK_NAV_ITEM = 'IS_BLOCK_NAV_ITEM';
export const IS_FULLSCREEN_PLAYER = 'IS_FULLSCREEN_PLAYER';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case IS_HIDE_MENU:
      return {
        ...state,
        isHideMenu: action.payload,
      };

    case IS_OPEN_MENU:
      return {
        ...state,
        isOpenMenu: action.payload,
      };

    case IS_BLOCK_NAV_ITEM:
      return {
        ...state,
        isBlockNavItem: action.payload,
      };

    case IS_FULLSCREEN_PLAYER:
      return {
        ...state,
        isFullscreenPlayer: action.payload,
      };

    default:
      return state;
  }
}

/**
 * @param {boolean} payload
 */
export function setIsHideMenu(payload) {
  return dispatch => {
    return dispatch({
      type: IS_HIDE_MENU,
      payload: payload,
    });
  };
}

/**
 * @param {boolean} payload
 */
export function setIsOpenMenu(payload) {
  return dispatch => {
    return dispatch({
      type: IS_OPEN_MENU,
      payload: payload,
    });
  };
}

export function setIsFullScreen(payload) {
  return dispatch => {
    return dispatch({
      type: IS_FULLSCREEN_PLAYER,
      payload: payload,
    });
  };
}

export function getIsHideMenu(state) {
  return state?.layout?.isHideMenu;
}

export function getIsOpenMenu(state) {
  return state?.layout?.isOpenMenu;
}

export function setIsBlockNavItem(payload) {
  return dispatch => {
    return dispatch({
      type: IS_BLOCK_NAV_ITEM,
      payload: payload,
    });
  };
}

export function getIsBlockNavItem(state) {
  return state?.layout?.isBlockNavItem;
}

export function getIsFullScreenPlayer(state) {
  return state?.layout?.isFullscreenPlayer;
}
