/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 */
export default function IconRewind(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 20;
  const height = props.height || 20;

  return (
    <svg width={width} height={height} viewBox="0 0 24 26" fill="none">
      <path
        d="M21.5843 18.5099C22.9184 19.2684 24 18.647 24 17.1232L24 5.87789C24 4.35251 22.9184 3.73194 21.5843 4.48969L11.6676 10.1266C10.333 10.8853 10.333 12.1146 11.6676 12.8732L21.5843 18.5099Z"
        fill={fill}
      />
      <path
        d="M7.64237 17.2648C8.57623 17.8164 9.33334 17.3645 9.33334 16.2563L9.33334 8.07784C9.33334 6.96847 8.57623 6.51715 7.64237 7.06825L0.700632 11.1678C-0.233529 11.7196 -0.23353 12.6136 0.700631 13.1653L7.64237 17.2648Z"
        fill={fill}
      />
    </svg>
  );
}
