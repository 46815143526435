import styles from '@app/styles/views/Home.module.sass';
import BannersSlider from '@components/BannersSlider';
import {
  KEY_NAV,
  keyNavAttribute,
  keyNavSelectors,
} from '@components/KeyNavItem';
import {useSelector} from 'react-redux';
import {useEffect, useRef, useState} from 'react';
import {getUserToken} from '@app/stores/auth';
import useHistory from '@app/hooks/useHistory';
import useChaptersNews from '@app/hooks/useChaptersNews';
import {getCategoriesFull, getCategoriesShows} from '@app/stores/shows';
import ShowsByCategory from '@components/ShowsByCategory';
import appRoutes from '@app/appRoutes';
import {useNavigate} from 'react-router-dom';
import MostRecentEpisodes from '@components/MostRecentEpisodes';
import useFreeShows from '@app/hooks/useFreeShows';
import HomeAfhsBanner from '@components/HomeAfhsBanner';
import EpisodesByCategory from '@components/EpisodesByCategory';
import useEpisodesBySeason from '@app/hooks/useEpisodesBySeason';
import HomeChannels24Hours from '@components/HomeChannels24Hours';
import Loading from '@components/Loading';
import deeplinkProcess from '@app/libs/deeplink';
import useBannersHome from '@app/hooks/useBannerHome';

const AFHS_CATEGORY_ID = 'Q2F0ZWdvcnk6Mi1ub3ZlbGFz';
const AFHS_SHOW_ID = 'U2hvdzoyLW5vdmVsYXMvMS1hbC1mb25kby1oYXktc2l0aW8=';
const AFHS_SEASON_ID =
  'U2Vhc29uOjItbm92ZWxhcy8xLWFsLWZvbmRvLWhheS1zaXRpby8xMS10ZW1wb3JhZGEtMTE=';

export default function Home() {
  const bannerHome = useBannersHome();
  const history_timeout = useRef(null);

  const userToken = useSelector(getUserToken);
  const history = useHistory();
  const showNews = useChaptersNews({loading: false});
  const categoriesShows = useSelector(getCategoriesShows);
  const freeShows = useFreeShows({
    availabilityType: useFreeShows.types.FREE,
  });
  const episodesFreeShows = useFreeShows({
    availabilityType: useFreeShows.types.FREEMIUM,
  });
  const afhsEpisodes = useEpisodesBySeason();
  const navigate = useNavigate();

  const [continueViewing, setContinueViewing] = useState(null);
  const [newsChapters, setNewsChapters] = useState(null);
  const [freeShowsList, setFreeShowsList] = useState(null);
  const [loading, setLoading] = useState(true);
  const categories = useSelector(getCategoriesFull);

  function getCategoryByIdShow(id) {
    const category_ = categories.find(category => {
      return category?.shows?.find(show => {
        if (show?.id === id) {
          return show;
        } else {
          return false;
        }
      });
    });
    return category_;
  }

  function onClickItemGrid(data, category) {
    if (!data?.id) {
      return;
    }
    if (
      category?.slug === 'continueViewing' ||
      category?.slug === 'newsChapters'
    ) {
      navigate(
        appRoutes.category(
          `${data?.categoryId}/${data?.showId}/${data?.seasonId}/${data.id}`,
        ),
      );
    } else if (category?.slug === 'afhsEpisodes') {
      navigate(
        appRoutes.category(
          `${AFHS_CATEGORY_ID}/${AFHS_SHOW_ID}/${AFHS_SEASON_ID}/${data?.id}`,
        ),
      );
    } else if (
      category?.slug === 'episodesFreeShows' ||
      category?.slug === 'freeShows'
    ) {
      const category_ = getCategoryByIdShow(data.id);
      if (category_) {
        navigate(appRoutes.category(`${category_.id}/${data.id}`));
      }
    } else {
      navigate(appRoutes.category(`${category.id}/${data.id}`));
    }
  }

  useEffect(() => {
    setLoading(true);
    clearTimeout(history_timeout.current);
    history_timeout.current = setTimeout(async () => {
      let h = null;
      let n = null;

      // trato de conseguir el history del usuario
      if (userToken) {
        try {
          h = await history.getData();
          if (h.data && h.data?.length > 0) {
            setContinueViewing({
              slug: 'continueViewing',
              title: 'Continuar viendo',
              shows: h.data,
            });
          }
        } catch (e) {
          console.log('history', e);
        }
      }

      // trato de conseguir los episodios mas recientes
      try {
        n = await showNews.getData();
        if (n && n?.length > 0) {
          setNewsChapters({
            slug: 'newsChapters',
            title: 'Episodios más recientes',
            shows: n,
          });
        }
      } catch (e) {
        console.log('news', e);
      }

      // obtengo shows free
      try {
        const free = await freeShows.getData();
        if (free?.length > 0) {
          setFreeShowsList({
            slug: 'freeShows',
            title: '',
            shows: free,
          });
        }
      } catch (e) {
        console.log('freeShows', e);
      }

      // obtengo shows con episodios free
      try {
        await episodesFreeShows.getData();
      } catch (e) {
        console.log('freeEpisodeShows', e);
      }

      // obtengo AFHS
      try {
        afhsEpisodes.getData({
          seasonId: AFHS_SEASON_ID,
          chapterFirst: 4,
          chapterAfter: '',
          chaptersSort: 'SORT_DESC',
        });
      } catch (e) {
        console.log('afhsEpisodes', e);
      }

      setLoading(false);
    }, 100);
  }, [userToken]);

  useEffect(() => {
    bannerHome.getData();
  }, []);

  return (
    <div className={styles.main} {...keyNavAttribute(KEY_NAV.SCROLL_DOWN)}>
      {loading && <Loading />}
      {!loading && (
        <>
          <BannersSlider
            id={'banner_slider'}
            data={bannerHome.data}
            onClick={url => {
              deeplinkProcess(url).then(response => {
                navigate(response.url);
              });
            }}
            keynavdown={keyNavSelectors()
              .querySelector(
                // `#showsGrid [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                `#showsGrid [${KEY_NAV.ITEM}]`,
              )
              .build()}
          />
          <div id="showsGrid" {...keyNavAttribute(KEY_NAV.PARENT)}>
            {continueViewing?.shows?.length > 0 && (
              <MostRecentEpisodes
                key={continueViewing.slug}
                label={continueViewing?.title}
                data={continueViewing?.shows}
                onClickItem={data => onClickItemGrid(data, continueViewing)}
                keynavdown={keyNavSelectors()
                  .relativeNthChildDown()
                  .steps(
                    'parentNode',
                    'parentNode',
                    'nextSibling',
                    KEY_NAV.ITEM_LAST_FOCUS,
                  )
                  .steps(
                    'parentNode',
                    'parentNode',
                    'nextSibling',
                    'nextSibling',
                    KEY_NAV.ITEM_LAST_FOCUS,
                  )
                  .build()}
                keynavup={keyNavSelectors()
                  .relativeNthChildUp()
                  .querySelector(
                    `#banner_slider [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#banner_slider [${KEY_NAV.ITEM}]`,
                  )
                  .build()}
                keynavleft={keyNavSelectors()
                  .borderLeft(
                    `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#menu [${KEY_NAV.ITEM}]`,
                  )
                  .build()}
              />
            )}

            {categoriesShows?.length > 0 && (
              <h1 className={styles.shows_title}>Todos los programas</h1>
            )}
            {categoriesShows?.map((category, index) => {
              return (
                <ShowsByCategory
                  key={category?.id}
                  id={index}
                  label={category?.title}
                  data={category?.shows}
                  onClickItem={data => onClickItemGrid(data, category)}
                  keynavdown={keyNavSelectors()
                    .steps(
                      'parentNode',
                      'parentNode',
                      'nextSibling',
                      KEY_NAV.ITEM_LAST_FOCUS,
                    )
                    .build()}
                  keynavup={keyNavSelectors()
                    .steps(
                      'parentNode',
                      'parentNode',
                      'previousSibling',
                      KEY_NAV.ITEM_LAST_FOCUS,
                    )
                    .steps(
                      'parentNode',
                      'parentNode',
                      'previousSibling',
                      'previousSibling',
                      KEY_NAV.ITEM_LAST_FOCUS,
                    )
                    .querySelector(
                      `#banner_slider [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                      `#banner_slider [${KEY_NAV.ITEM}]`,
                    )
                    .build()}
                  keynavleft={keyNavSelectors()
                    .borderLeft(
                      `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                      `#menu [${KEY_NAV.ITEM}]`,
                    )
                    .build()}
                />
              );
            })}

            <HomeChannels24Hours
              id={'home_channels'}
              onClick={data => {
                navigate(appRoutes.channels(data?.id));
              }}
              keynavscrolltoel={'#home_channels'}
              keynavup={keyNavSelectors()
                .steps(
                  'parentNode',
                  'parentNode',
                  'previousSibling',
                  KEY_NAV.ITEM_LAST_FOCUS,
                )
                .steps(
                  'parentNode',
                  'parentNode',
                  'previousSibling',
                  KEY_NAV.ITEM,
                )
                .build()}
              keynavdown={keyNavSelectors()
                .steps(
                  'parentNode',
                  'parentNode',
                  'nextSibling',
                  KEY_NAV.ITEM_LAST_FOCUS,
                )
                .steps(
                  'parentNode',
                  'parentNode',
                  'nextSibling',
                  'nextSibling',
                  KEY_NAV.ITEM_LAST_FOCUS,
                )
                .steps('parentNode', 'parentNode', 'nextSibling', KEY_NAV.ITEM)
                .build()}
            />

            {newsChapters?.shows?.length > 0 && (
              <MostRecentEpisodes
                key={newsChapters.slug}
                label={newsChapters?.title}
                data={newsChapters?.shows}
                onClickItem={data => onClickItemGrid(data, newsChapters)}
                keynavdown={keyNavSelectors()
                  .relativeNthChildDown()
                  .steps(
                    'parentNode',
                    'parentNode',
                    'nextSibling',
                    KEY_NAV.ITEM_LAST_FOCUS,
                  )
                  .steps(
                    'parentNode',
                    'parentNode',
                    'nextSibling',
                    'nextSibling',
                    KEY_NAV.ITEM_LAST_FOCUS,
                  )
                  .build()}
                keynavup={keyNavSelectors()
                  .relativeNthChildUp()
                  .steps(
                    'parentNode',
                    'parentNode',
                    'previousSibling',
                    KEY_NAV.ITEM_LAST_FOCUS,
                  )
                  .build()}
                keynavleft={keyNavSelectors()
                  .borderLeft(
                    `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#menu [${KEY_NAV.ITEM}]`,
                  )
                  .build()}
              />
            )}

            {episodesFreeShows.data?.length > 0 && (
              <h1 className={styles.shows_title}>
                Disfruta también de estos programas con{' '}
                <span>episodios gratis</span>
              </h1>
            )}

            {episodesFreeShows.data?.length > 0 && (
              <ShowsByCategory
                key={'episodesFreeShows'}
                episodesFree={true}
                label={''}
                data={episodesFreeShows?.data}
                onClickItem={data =>
                  onClickItemGrid(data, {
                    slug: 'episodesFreeShows',
                    shows: episodesFreeShows,
                  })
                }
                keynavdown={keyNavSelectors()
                  .steps(
                    'parentNode',
                    'parentNode',
                    'nextSibling',
                    KEY_NAV.ITEM_LAST_FOCUS,
                  )
                  .steps(
                    'parentNode',
                    'parentNode',
                    'nextSibling',
                    'nextSibling',
                    KEY_NAV.ITEM_LAST_FOCUS,
                  )
                  //.querySelector(
                  //  `#home_channels [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                  //  `#home_channels [${KEY_NAV.ITEM}]`,
                  //)
                  .build()}
                keynavup={keyNavSelectors()
                  .steps(
                    'parentNode',
                    'parentNode',
                    'previousSibling',
                    KEY_NAV.ITEM_LAST_FOCUS,
                  )
                  .steps(
                    'parentNode',
                    'parentNode',
                    'previousSibling',
                    'previousSibling',
                    KEY_NAV.ITEM_LAST_FOCUS,
                  )
                  .build()}
                keynavleft={keyNavSelectors()
                  .borderLeft(
                    `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#menu [${KEY_NAV.ITEM}]`,
                  )
                  .build()}
              />
            )}

            {freeShowsList?.shows?.length > 0 && (
              <h1 className={styles.shows_title}>
                Disfruta también de estos programas <span>gratis</span>
              </h1>
            )}
            {freeShowsList?.shows?.length > 0 && (
              <ShowsByCategory
                key={freeShowsList?.slug}
                showFree={true}
                label={freeShowsList?.title}
                data={freeShowsList?.shows}
                onClickItem={data => onClickItemGrid(data, freeShowsList)}
                keynavdown={keyNavSelectors()
                  .steps(
                    'parentNode',
                    'parentNode',
                    'nextSibling',
                    KEY_NAV.ITEM_LAST_FOCUS,
                  )
                  .querySelector(
                    `#home_channels [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#home_channels [${KEY_NAV.ITEM}]`,
                  )
                  .steps(
                    'parentNode',
                    'parentNode',
                    'nextSibling',
                    'nextSibling',
                    KEY_NAV.ITEM_LAST_FOCUS,
                  )
                  .build()}
                keynavup={keyNavSelectors()
                  .steps(
                    'parentNode',
                    'parentNode',
                    'previousSibling',
                    KEY_NAV.ITEM_LAST_FOCUS,
                  )
                  .steps(
                    'parentNode',
                    'parentNode',
                    'previousSibling',
                    'previousSibling',
                    KEY_NAV.ITEM_LAST_FOCUS,
                  )
                  .build()}
                keynavleft={keyNavSelectors()
                  .borderLeft(
                    `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#menu [${KEY_NAV.ITEM}]`,
                  )
                  .build()}
              />
            )}

            {afhsEpisodes?.data?.chapters?.length > 0 && (
              <HomeAfhsBanner id="HomeAfhsBanner">
                <EpisodesByCategory
                  label={''}
                  showTitle="Al Fondo Hay Sitio"
                  data={afhsEpisodes.data.chapters}
                  onClickItem={data =>
                    onClickItemGrid(data, {
                      slug: 'afhsEpisodes',
                    })
                  }
                  keynavscrolltoel={'#HomeAfhsBanner'}
                  keynavdown={keyNavSelectors()
                    .steps(
                      'parentNode',
                      'parentNode',
                      'parentNode',
                      'parentNode',
                      'parentNode',
                      'nextSibling',
                      'nextSibling',
                      KEY_NAV.ITEM_LAST_FOCUS,
                    )
                    .build()}
                  keynavup={keyNavSelectors()
                    .steps(
                      'parentNode',
                      'parentNode',
                      'parentNode',
                      'parentNode',
                      'parentNode',
                      'previousSibling',
                      KEY_NAV.ITEM_LAST_FOCUS,
                    )
                    .steps(
                      'parentNode',
                      'parentNode',
                      'previousSibling',
                      'previousSibling',
                      KEY_NAV.ITEM_LAST_FOCUS,
                    )
                    .steps(
                      'parentNode',
                      'parentNode',
                      'parentNode',
                      'parentNode',
                      'parentNode',
                      'previousSibling',
                      'previousSibling',
                      KEY_NAV.ITEM_LAST_FOCUS,
                    )
                    .build()}
                  keynavleft={keyNavSelectors()
                    .borderLeft(
                      `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                      `#menu [${KEY_NAV.ITEM}]`,
                    )
                    .build()}
                />
              </HomeAfhsBanner>
            )}
          </div>
        </>
      )}
    </div>
  );
}
