import styles from '@app/styles/components/HomeAfshBanner.module.sass';

export default function HomeAfhsBanner({id, children}) {
  return (
    <section id={id} className={styles.main}>
      <div
        className={styles.bgImage}
        style={{
          backgroundImage: "url('images/home-afhs.png')",
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}>
        <div className={styles.ctn}>
          <div className={styles.content}>
            <div className={styles.flag}>
              <img src="images/24-hours.png" alt="24 horas" />
              <p>
                Cada 24 hrs. 1 Cap. <span>Gratis</span>
              </p>
            </div>
            <h2 className={styles.title}>
              AFHS: Temporada 11 <span>ahora disponible</span>
            </h2>
          </div>
          {children}
        </div>
      </div>
    </section>
  );
}
