import useScrollDrag from '@app/hooks/useScrollDrag';
import styles from '@app/styles/components/ShowsByCategory.module.sass';
import Showthumb from '@components/Showthumb';
import {useRef} from 'react';
import {keyNavAttribute, KEY_NAV} from './KeyNavItem';

/**
 * ShowsByCategory.
 *
 * @param {object} props
 * @param {string} props.label
 * @param {array} props.data
 * @param {function} props.onFocusItem
 * @param {function} props.onClickItem
 * @param {string|string[]} props.keynavup
 * @param {string|string[]} props.keynavdown
 * @param {string|string[]} props.keynavleft
 * @param {string|string[]} props.keynavright
 * @param {boolean} props.showFree
 * @param {boolean} props.episodesFree
 */
export default function ShowsByCategory(props) {
  const slider = useRef(null);
  useScrollDrag(slider);

  return (
    <div className={styles.main}>
      <p className={styles.label}>{props.label}</p>
      <div
        className={styles.items}
        ref={slider}
        {...keyNavAttribute(KEY_NAV.PARENT)}>
        {props?.data?.map((item, index) => {
          return (
            <Showthumb
              key={`${item?.slug}-${index}`}
              slug={item?.slug}
              title={item?.title}
              image={item?.images[0]?.url}
              keynavup={props.keynavup}
              keynavdown={props.keynavdown}
              keynavleft={props.keynavleft}
              keynavright={props.keynavright}
              showFree={props.showFree}
              episodesFree={props.episodesFree}
              onClick={() => {
                if (typeof props.onClickItem === 'function') {
                  props.onClickItem(item);
                }
              }}
              onFocus={() => {
                if (typeof props.onFocusItem === 'function') {
                  props.onFocusItem(item, index);
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
