/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 * @param {string} props.className
 */
export default function IconSchedule(props) {
  const width = props.width || 40;
  const height = props.height || 40;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none">
      <path
        d="M12.2177 12.6905H29.0823M11.4575 3.33317V6.38584M11.4575 6.38584L29.1653 6.38551M11.4575 6.38584C8.52343 6.38584 6.14522 8.80522 6.14535 11.79L6.14617 29.804C6.14631 32.7886 8.52469 35.208 11.4585 35.208H29.1663C32.1004 35.208 34.4788 32.7883 34.4787 29.8035L34.4779 11.7895C34.4777 8.80491 32.0991 6.38551 29.1653 6.38551M29.1653 3.33301V6.38551M16.7711 28.9031V18.0946L13.2295 20.7967M26.5104 28.9031V18.0946L22.9688 20.7967"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
