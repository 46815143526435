import useFavorites from '@app/hooks/useFavorites';
import useFilterShows from '@app/hooks/useFilterShows';
import styles from '@app/styles/views/Favorites.module.sass';
import {getShowImageSource} from '@app/utils/getShowImageSource';
import Loading from '@components/Loading';
import Showthumb from '@components/Showthumb';
import {useNavigate} from 'react-router-dom';
import {useEffect, useRef} from 'react';
import appRoutes from '@app/appRoutes';
import {trackEvent} from '@app/libs/metrics';
import {ACTIONS} from '@app/constants';
import {useSelector} from 'react-redux';
import {getUserId} from '@app/stores/auth';
import {useState} from 'react';
import {equalArray} from '@app/utils/equal';
import {
  focus,
  keyNavAttribute,
  keyNavSelectors,
  KEY_NAV,
} from '@components/KeyNavItem';
import {useLayoutEffect} from 'react';
import {getCategoriesFull} from '@app/stores/shows';

export default function Favorites() {
  const favoritesState = useFavorites();
  const favoritesShows = useFilterShows();
  const isRednered = useRef(false);
  const navigate = useNavigate();
  const userId = useSelector(getUserId);
  const [favorites, setFavorites] = useState([]);
  const lastFavorites = useRef(null);
  const ref = useRef();
  const isLayoutRendered = useRef(false);
  const request_data_id = useRef(null);
  const [loading, setLoading] = useState(true);
  const categories = useSelector(getCategoriesFull);

  function onClickItemGrid(data) {
    if (data?.id) {
      trackEvent({
        action: ACTIONS.PAGE.programClicked,
      });

      const category = categories.find(category => {
        return category?.shows?.find(show => {
          if (show?.id === data.id) {
            return show;
          } else {
            return false;
          }
        });
      });
      navigate(appRoutes.category(`${category?.id}/${data?.id}`));
    }
  }

  useEffect(() => {
    if (!isRednered.current) {
      isRednered.current = true;
      trackEvent({
        action: ACTIONS.PROFILE.FavoriteShowed,
      });
    }
  }, []);

  useEffect(() => {
    async function getData() {
      try {
        const shows = await favoritesState.getData();
        if (
          shows?.length > 0 &&
          !equalArray(shows, lastFavorites.current || [])
        ) {
          lastFavorites.current = shows;
          await favoritesShows.getData({
            showIds: shows,
          });
        } else if (shows?.length === 0) {
          favoritesShows.setLoading(false);
          setFavorites([]);
        }
      } catch (e) {
        console.log('getData.error', e);
      } finally {
        setLoading(false);
      }
    }

    clearTimeout(request_data_id.current);

    request_data_id.current = setTimeout(() => {
      if (userId && favoritesState.loading === false) {
        getData();
      }
    }, 200);
  }, [userId]);

  useEffect(() => {
    if (favoritesShows.data?.length > 0) {
      setFavorites(favoritesShows.data);
    }
  }, [favoritesShows.data]);

  useLayoutEffect(() => {
    if (!isLayoutRendered.current) {
      let current = ref.current?.querySelector(`[${KEY_NAV.ITEM}]`);

      if (current) {
        isLayoutRendered.current = true;
        focus(current);
      }
    }
  });

  return (
    <div ref={ref} className={styles.main}>
      <div className={styles.inner}>
        <h1 className={styles.title}>Favoritos</h1>
        {!loading && (
          <div
            className={styles.content}
            {...keyNavAttribute(KEY_NAV.SCROLL_DOWN)}>
            <div className={styles.grid} {...keyNavAttribute(KEY_NAV.PARENT)}>
              {favorites?.length === 0 && <p>No hay favoritos</p>}
              {favorites?.map(item => {
                return (
                  <Showthumb
                    key={item?.id}
                    title={item?.title}
                    slug={item?.id}
                    image={getShowImageSource(item?.images)}
                    onClick={() => onClickItemGrid(item)}
                    keynavdown={keyNavSelectors()
                      .relativeNthChildDown()
                      .build()}
                    keynavup={keyNavSelectors().relativeNthChildUp().build()}
                    keynavleft={keyNavSelectors()
                      .borderLeft(
                        `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                        `#menu [${KEY_NAV.ITEM}]`,
                      )
                      .build()}
                    keynavright={keyNavSelectors()
                      .borderRight(`[${KEY_NAV.ITEM_FOCUS}]`)
                      .build()}
                  />
                );
              })}
            </div>
          </div>
        )}
        {loading && <Loading />}
      </div>
    </div>
  );
}
