import {generateQuery, useGQL3} from './useGQL3';

/**
 * @namespace useSeasonsList
 */
export default function useSeasonsList() {
  /**
   * @memberof
   */
  function parseResponse(data) {
    return data?.data?.show?.seasons?.edges.map(({node: season}) => {
      return season;
    });
  }

  /**
   * @memberof
   */
  function getQuery(payload = {}) {
    return generateQuery(
      `query seasonsList($showId: GlobalID!, $seasonSort: SortEnum!, $metadataType: MetadataTypeEnum!) {
        show: node(id: $showId){
          ... on Show {
            seasons(sort: $seasonSort){
              edges {
                node {
                  title
                  id
                  metadata(metadataType: $metadataType) {
                    value
                  }
                }
              }
            }
          }
        }
      }`,
      {
        showId: '',
        seasonSort: 'SORT_DESC',
        metadataType: 'VAST_ANDROID',
        ...payload,
      },
    );
  }

  return useGQL3(parseResponse, getQuery);
}
