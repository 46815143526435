import appRoutes from '@app/appRoutes';
import Menu from '@components/Menu';
import {Routes, Route} from 'react-router-dom';
import Home from '@views/Home';
import Search from '@views/Search';
import VideoLive from '@views/VideoLive';
import Favorites from '@views/Favorites';
import Profile from '@views/Profile';
import Category from '@views/Category';
import Detail from '@views/Detail';
import {useDispatch, useSelector} from 'react-redux';
import {addUserToken, getUserToken, logoutUser} from './stores/auth';
import Login from '@views/Login';
import Support from '@views/Support';
import Account from '@views/Account';
import VideoVOD from '@views/VideoVOD';
import {useEffect, useRef} from 'react';
import {Navigate} from 'react-router-dom';
import useShowsByHome from './hooks/useShowsByHome';
import Loading from '@components/Loading';
import {addShows} from './stores/shows';
import {keyNavAttribute, KEY_NAV} from '@components/KeyNavItem';
import {fetchConfig, refreshToken, validateJWT} from './api/repository';
import {useCallback} from 'react';
import {removeProfile} from './stores/profile';
import jwt_decode from 'jwt-decode';
import Alert from '@components/Alert';
import {useState} from 'react';
import {IconAd} from '@components/icons/IconAd';
import Button from '@components/Button';
import {setIsBlockNavItem} from './stores/layout';
import AppInfo from '@views/AppInfo';
import {PopUp} from '@components/PopUp';
import {
  STATUS_TYPE,
  setIsPopupActive,
  setPopupMessage,
} from '@app/stores/popup';
import {setUserProperties} from './libs/metrics';
import platform from '@app/platform';
import Schedule from '@views/Schedule';
import Channels from '@views/Channels';
import {ISGLOBAL} from './constants';
import {useNavigate} from 'react-router-dom';

export default function Root() {
  const token = useSelector(getUserToken);
  const dispatch = useDispatch();
  const isRendered = useRef(null);
  const categoriesShows = useShowsByHome();
  const [theModalAlert, setTheModalAlert] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleOnline = () => {
      dispatch(setPopupMessage(STATUS_TYPE.RECONNECTION_SUCCESS));
      setTimeout(() => {
        dispatch(setIsPopupActive(false));
      }, 3500);
      setTimeout(() => {
        dispatch(setPopupMessage(''));
      }, 4000);
    };

    const handleOffline = () => {
      dispatch(setIsPopupActive(true));
      dispatch(setPopupMessage(STATUS_TYPE.CONNECTION_ERROR));
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (!isRendered.current) {
      isRendered.current = true;
      setUserProperties({
        app_id: platform.bundleId,
        app_version: platform.version,
        os_version: platform.OSVersion,
      });
    }
  }, []);

  useEffect(() => {
    categoriesShows.getData();
  }, []);

  useEffect(() => {
    if (categoriesShows?.data?.length > 0) {
      dispatch(addShows(categoriesShows.data));
    }
  }, [categoriesShows.data]);

  const tokenExpiredMessage = useRef(null);

  async function logoutHandler() {
    try {
      dispatch(logoutUser());
      dispatch(removeProfile());
    } catch (e) {
      console.log(
        'No se pudo completar la acción. Inténtelo nuevamente más tarde',
      );
    }
  }

  const alertAndLogout = useCallback(() => {
    if (token && tokenExpiredMessage.current === null) {
      tokenExpiredMessage.current = true;
      logoutHandler();
      dispatch(setIsBlockNavItem(true));
      setTheModalAlert({
        title: (
          <>
            <IconAd />
            <br />
            <span>Tu sesión expiró</span>
          </>
        ),
        body: (
          <>
            <strong>Por favor, vuelva a ingresar</strong>
          </>
        ),
        footer: (
          <>
            <Button
              theme={'tiny'}
              label={'Aceptar'}
              onClick={() => {
                setTheModalAlert(null);
                dispatch(setIsBlockNavItem(false));
                navigate(appRoutes.login() + '?isSuccessBack=true');
              }}
            />
          </>
        ),
      });
      tokenExpiredMessage.current = null;
    }
  }, [token]);

  useEffect(() => {
    fetchConfig.onUnauthorized = () => {
      return new Promise((resolve, reject) => {
        if (!token) {
          reject();
        } else {
          refreshToken(token)
            .then(async response => {
              try {
                if (response.access_token) {
                  const jwt = await validateJWT(response.access_token);
                  if (jwt?.error) {
                    alertAndLogout();
                    reject(jwt.error);
                  } else {
                    dispatch(
                      addUserToken({
                        ...jwt,
                      }),
                    );
                    resolve(response.access_token);
                  }
                } else {
                  alertAndLogout();
                  reject();
                }
              } catch (e) {
                alertAndLogout();
                reject(e);
              }
            })
            .catch(e => {
              alertAndLogout();
              reject(e);
            });
        }
      });
    };

    async function verifyToken() {
      if (token) {
        const jwt = jwt_decode(token);
        if (!jwt?.grant_type) {
          alertAndLogout();
        }
      }
    }
    verifyToken();
  }, [token]);

  return (
    <div className="App" {...keyNavAttribute(KEY_NAV.CONTENT)}>
      {theModalAlert && (
        <Alert
          title={theModalAlert?.title}
          body={theModalAlert?.body}
          footer={theModalAlert?.footer}
        />
      )}
      {categoriesShows.loading && <Loading fixed={true} />}
      {!categoriesShows.loading && (
        <>
          <Menu />
          <div id="app-content">
            <Routes>
              <Route path={appRoutes.login()} element={<Login />} />
              <Route path={appRoutes.home()} element={<Home />} />
              <Route
                path={appRoutes.category(':category_id')}
                element={<Category />}
              />
              <Route
                path={appRoutes.category(':category_id/:show_id')}
                element={<Detail />}
              />
              <Route
                path={appRoutes.category(
                  ':category_id/:show_id/:season_id/:chapter_id',
                )}
                element={<VideoVOD />}
              />

              <Route path={appRoutes.search()} element={<Search />} />
              <Route path={appRoutes.search(':show_id')} element={<Detail />} />
              <Route
                path={appRoutes.search(':show_id/:season_id/:chapter_id')}
                element={<VideoVOD />}
              />

              <Route path={appRoutes.live()} element={<VideoLive />} />
              {!ISGLOBAL && (
                <Route path={appRoutes.channels()} element={<Channels />} />
              )}
              {!ISGLOBAL && (
                <Route
                  path={appRoutes.channels(':channel_id')}
                  element={<Channels />}
                />
              )}
              {token && (
                <>
                  <Route path={appRoutes.profile()} element={<Profile />} />
                  <Route
                    path={appRoutes.profile('soporte')}
                    element={<Support />}
                  />
                  <Route
                    path={appRoutes.profile('cuenta')}
                    element={<Account />}
                  />
                  <Route path={appRoutes.favorites()} element={<Favorites />} />
                  <Route path={appRoutes.schedule()} element={<Schedule />} />
                  <Route
                    path={appRoutes.profile('appinfo')}
                    element={<AppInfo />}
                  />
                </>
              )}
              {!token && (
                <>
                  <Route path={appRoutes.profile()} element={<Login />} />
                  <Route
                    path={appRoutes.profile('*')}
                    element={<Navigate to={appRoutes.profile()} />}
                  />
                  <Route path={appRoutes.favorites()} element={<Login />} />
                  <Route path={appRoutes.schedule()} element={<Schedule />} />
                  <Route
                    path={appRoutes.favorites('*')}
                    element={<Navigate to={appRoutes.favorites()} />}
                  />
                </>
              )}
            </Routes>
          </div>
        </>
      )}
      <PopUp />
    </div>
  );
}
