/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 * @param {string} props.className
 */

export default function IconSearch2(props) {
  const width = props.width || 40;
  const height = props.height || 40;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none">
      <path
        d="M28.2114 28.4L34 34M32.1333 19.0667C32.1333 26.2832 26.2832 32.1333 19.0667 32.1333C11.8501 32.1333 6 26.2832 6 19.0667C6 11.8501 11.8501 6 19.0667 6C26.2832 6 32.1333 11.8501 32.1333 19.0667Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
}
