import appRoutes from '@app/appRoutes';
import useSearch from '@app/hooks/useSearch';
import {setIsOpenMenu} from '@app/stores/layout';
import {
  getSearchResult,
  removeSearchResult,
  saveSearchResult,
} from '@app/stores/search';
import styles from '@app/styles/views/Search.module.sass';
import {getShowImageSource} from '@app/utils/getShowImageSource';
import {
  keyNavAttribute,
  keyNavSelectors,
  KEY_NAV,
} from '@components/KeyNavItem';
import Loading from '@components/Loading';
import SearchBar from '@components/SearchBar';
import SearchBarSimple from '@components/SearchBarSimple';
import Showthumb from '@components/Showthumb';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import platform from '../platform';

const SearchInput = platform.isTizen ? SearchBarSimple : SearchBar;

export default function Search() {
  const search = useSearch();
  const searchTimeout = useRef(null);
  const navigate = useNavigate();
  const [state, setState] = useState([]);
  const isRendered = useRef(false);
  const dispatch = useDispatch();
  const searchResultSaved = useSelector(getSearchResult);
  const searchForm = useRef();
  const searchBarRef = useRef(null);

  function onSearchHandler(text) {
    if (text) {
      clearTimeout(searchTimeout.current);
      searchTimeout.current = setTimeout(() => {
        search.getData({
          search: text,
        });
      }, 700);
    } else {
      setState([]);
    }
  }

  function onClickItemGrid(data) {
    if (data?.id) {
      dispatch(saveSearchResult(state));
      navigate(appRoutes.category(`${data?.category?.id}/${data.id}`));
    }
  }

  function onParentBlurHandler() {
    document.querySelector('#search-form input')?.blur();
  }

  useEffect(() => {
    if (isRendered.current && searchTimeout.current !== null) {
      const d = search.data || [];
      setState(d);
    }
  }, [search.loading]);

  useEffect(() => {
    if (!isRendered.current) {
      searchBarRef?.current?.focus();
      isRendered.current = true;
      try {
        setState(searchResultSaved);
        dispatch(removeSearchResult());
      } catch (e) {
        console.log('parse.searchData', e);
      }

      if (typeof searchForm.current?.addEventListener === 'function') {
        searchForm.current?.addEventListener(
          KEY_NAV.EVENT_PARENT_BLUR,
          onParentBlurHandler,
        );
      }
    }
    return () => {
      if (typeof searchForm.current?.removeEventListener === 'function') {
        searchForm.current?.removeEventListener(
          KEY_NAV.EVENT_PARENT_BLUR,
          onParentBlurHandler,
        );
      }
    };
  }, []);

  return (
    <div {...keyNavAttribute(KEY_NAV.SCROLL_DOWN)} className={styles.main}>
      <div
        ref={searchForm}
        id="search-form"
        className={styles.header}
        {...keyNavAttribute(KEY_NAV.PARENT)}>
        <SearchInput
          ref={searchBarRef}
          onFocus={() => dispatch(setIsOpenMenu(false))}
          onChangeText={onSearchHandler}
          keynavleft={keyNavSelectors()
            .querySelector(
              `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
              `#menu-main [${KEY_NAV.ITEM}]`,
            )
            .build()}
          keynavdown={keyNavSelectors()
            .querySelector(
              `#search-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
              `#search-main [${KEY_NAV.ITEM}]`,
            )
            .build()}
        />
      </div>
      <div
        id="search-main"
        {...keyNavAttribute(KEY_NAV.PARENT)}
        className={styles.content}>
        {search.loading && <Loading />}
        {!search.loading && search.error && (
          <p className={styles.empty}>No se pudo completar la búsqueda</p>
        )}
        {!search.loading && state?.length === 0 && (
          <p className={styles.empty}>Sin resultados que mostrar</p>
        )}
        {state?.length > 0 &&
          state.map(item => {
            return (
              <Showthumb
                key={item?.id}
                title={item?.title}
                slug={item?.id}
                image={getShowImageSource(item?.images)}
                onClick={() => onClickItemGrid(item)}
                keynavdown={keyNavSelectors().relativeNthChildDown().build()}
                keynavup={keyNavSelectors()
                  .onlyFirstRow(
                    `#search-form [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#search-form [${KEY_NAV.ITEM}]`,
                  )
                  .relativeNthChildUp()
                  .build()}
                keynavleft={keyNavSelectors()
                  .borderLeft(
                    `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#menu [${KEY_NAV.ITEM}]`,
                  )
                  .build()}
                keynavright={keyNavSelectors()
                  .borderRight(`[${KEY_NAV.ITEM_FOCUS}]`)
                  .build()}
              />
            );
          })}
      </div>
    </div>
  );
}
