import {imageSize} from '@app/utils/imageSize';
import {generateQuery, useGQL3} from './useGQL3';

/**
 * @typedef {object} Chapter
 * @property {string} title
 * @property {string} slug
 * @property {object} images
 * @property {{ url: string, type: string }[]} images
 * @property {string} showSlug
 * @property {string} seasonSlug
 */

/**
 * @typedef {object} getDataPayload
 * @property {string?} first
 * @property {string?} imageType
 * @property {string?} imageSize
 * @property {string?} quality
 */

/**
 * @returns {{
 *  data: Chapter[],
 *  getData: function(getDataPayload): Promise<Chapter[]>
 * }}
 */
export default function useChaptersNews() {
  function parseResponse(data) {
    return data?.data?.lastUpdatedChapters?.edges.map(({node}) => node);
  }

  /**
   * @para.datam {getDataPayload} payload
   */
  function getQuery(payload = {}) {
    return generateQuery(
      `query chapterNews($imageType: ImageTypeEnum!, $imageSize: String!, $metadataType: MetadataTypeEnum!)  {
        lastUpdatedChapters(first: 8) {
          edges {
            node {
              free
              id
              title
              duration
              images(imageType: $imageType){
                url(size: $imageSize)
              }
              metadata(metadataType: $metadataType) {
                value
              }
              showTitle
              showId
              categoryId
              seasonId
            }
          }
        }
      }`,
      {
        imageSize: imageSize(imageSize.type.chapter),
        imageType: 'LANDSCAPE',
        first: 5,
        metadataType: 'VAST_ANDROID',
        ...payload,
      },
    );
  }
  return useGQL3(parseResponse, getQuery);
}
