import {generateQuery, useGQL3} from './useGQL3';

const ids = {
  '65fc43190d37be36e84e931f': 'Novelas',
  '657fbe15e3ba9363bb07c6b4': 'AFHS',
};

export default function useChannels() {
  function parseResponse(data) {
    return data?.data?.fastChannels?.map(channel => {
      channel.title = ids[channel.id];
      return channel;
    });
  }

  function getQuery(payload) {
    return generateQuery(
      `query channels {
        fastChannels {
          id
          source
          thumbnails(format: WEBP)
          scheduleJob {
            name
            dateStart
            dateEnd
            showName
            images
          }
        }
      }`,
      {
        ...payload,
      },
    );
  }

  return useGQL3(parseResponse, getQuery);
}
