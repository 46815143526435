import {imageSize} from '@app/utils/imageSize';
import {generateQuery, useGQL3} from './useGQL3';

export default function useDetail() {
  function parseResponse(payload) {
    const data = payload?.data;
    return {
      ageRate: data?.show?.ageRate,
      broadcast: data?.show?.broadcast,
      description: data?.show?.description,
      images: data?.show?.images,
      metadata: data?.show?.metadata,
      id: data?.show?.id,
      title: data?.show?.title,
      webSlug: data?.show?.webSlug,
    };
  }

  function getQuery(payload = {}) {
    return generateQuery(
      `query showDetail($showId: GlobalID!, $imageType: ImageTypeEnum!, $imageSize: String!) {
        show: node(id: $showId){
          ... on Show {
            id
            title
            description
            ageRate
            broadcast
            webSlug
            metadata {
              value
            }
            images(imageType: $imageType){
              url(size: $imageSize)
            }
          }
        }
      }`,
      {
        imageSize: imageSize(imageSize.type.banner),
        imageType: 'LANDSCAPE',
        seasonSort: 'SORT_DESC',
        ...payload,
      },
    );
  }

  return useGQL3(parseResponse, getQuery);
}
