import {imageSize} from '@app/utils/imageSize';
import {generateQuery, useGQL3} from './useGQL3';

/**
 * @typedef {object} Chapter
 * @property {string} free
 * @property {string} id
 * @property {string} title
 * @property {string} duration
 * @property {object[]} images
 * @property {string} images.url
 * @property {object[]} media
 * @property {string} media.mediaId
 * @property {object} metadataType
 * @property {string} metadataType.value
 */

/**
 * @typedef {object} getDataPayload
 * @property {string} seasonId
 * @property {string?} chaptersSort
 * @property {string?} chapterFirst
 * @property {string?} imageSize
 * @property {string?} imageType
 * @property {string?} chapterAfter
 * @property {string?} metadataType
 */

/**
 * @returns {{
 *   data: Chapter[],
 *   getData: function(args:getDataPayload): Promise<Chapter[]>
 * }}
 */
export default function useEpisodesBySeason() {
  /**
   * @returns {Chapter[]}
   */
  function parseResponse(data) {
    return {
      chapters: data?.data?.season?.chapters?.edges?.map(({node}) => node),
      cursor: data?.data?.season?.chapters?.pageInfo?.endCursor,
    };
  }

  /**
   * @param {getDataPayload} payload
   **/
  function getQuery(payload = {}) {
    return generateQuery(
      `query seasonChapters($seasonId: GlobalID!, $imageSize: String!, $imageType: ImageTypeEnum!, $chaptersSort: SortEnum!, $chapterFirst: Int!, $metadataType: MetadataTypeEnum!, ${
        payload?.chapterAfter ? '$chapterAfter: String!' : ''
      }){
        season: node(id: $seasonId) {
          ... on Season {
            chapters(first: $chapterFirst, sort: $chaptersSort ${
              payload?.chapterAfter ? 'after: $chapterAfter' : ''
            }) {
              pageInfo {
                endCursor
              }
              edges {
                node {
                  id
                  free
                  title
                  duration
                  images(imageType: $imageType){
                    url(size: $imageSize)
                    progressUrl(size: $imageSize)
                  }
                  metadata(metadataType: $metadataType) {
                    value
                  }
                }
              }
            }
          }
        }
      }`,
      {
        seasonId: '',
        chaptersSort: 'SORT_ASC',
        chapterFirst: 6,
        imageSize: imageSize(imageSize.type.show),
        imageType: 'POSTER',
        metadataType: 'VAST_ANDROID',
        ...payload,
      },
    );
  }

  return useGQL3(parseResponse, getQuery);
}
