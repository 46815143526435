export const API_AUTH_DOMAIN = process?.env?.REACT_APP_API_AUTH_DOMAIN;
export const API_URL = process?.env?.REACT_APP_API_URL;
export const API_URL_AUTH = `https://${API_AUTH_DOMAIN}`;
export const AUTH_CLIENT_ID = 'MGAwXygVGjdBVEL5iEa8GEn9';
export const APP_NAME = 'tvgoweb';
export const ISGLOBAL =
  process?.env?.REACT_APP_GLOBAL === 'true' ? true : false;

// ---------------------------------------------------------
// channels
// ---------------------------------------------------------
export const API_MEDIA_STREAM = 'https://platform.mediastre.am/api/';
export const API_MEDIA_STREAM_TOKEN = '5d69b3d26d7180472d29940996d85c75';
export const API_GET_TOKEN_FOR_API_MEDIA_STREAM =
  'https://media.tvgo.americatv.com.pe/app/';
// ---------------------------------------------------------

export const ACTIONS = {
  PLAYER: {
    videoStart: 'videoStart',
    videoResume: 'videoResume',
    videoPause: 'videoPause',
    videoPlaythrough: 'videoPlaythrough',
    videoEnd: 'videoEnd',
    videoPlayTry: 'videoPlayTry',
    videoNext: 'videoNext',
  },
  PAGE: {
    FooterClicked: 'FooterClicked',
    LaunchApp: 'LaunchApp',
    FootSectionClicked: 'FootSectionClicked',
    BotonVivoClicked: 'BotonVivoClicked',
    CarouselClicked: 'CarouselClicked',
    seasonClicked: 'seasonClicked',
    programClicked: 'programClicked',
    scrollDepht: 'scrollDepht',
    VoteClicked: 'VoteClicked',
    LogInRegisterShowed: 'LogInRegisterShowed',
    channelsShowed: 'channelsShowed',
    channelClicked: 'channelClicked',
  },
  LOGIN: {
    LogInClicked: 'LogInClicked',
    LogInShowed: 'LogInShowed',
    LogInStarted: 'LogInStarted',
    LogInFailed: 'LogInFailed',
    LoginSucces: 'LoginSucces',
    forgetPassClicked: 'forgetPassClicked',
    forgetPassShowed: 'forgetPassShowed',
    SubscriptionClicked: 'SubscriptionClicked',
    RegisterSucces: 'RegisterSucces',
  },
  PAYWALL: {
    SubscriptionClicked: 'SubscriptionClicked',
    SuscripcionLeaved: 'SuscripcionLeaved',
    SubscriptionShowed: 'SubscriptionShowed',
    SubscriptionContinue: 'SubscriptionContinue',
    PlanShowed: 'PlanShowed',
    PlanSelected: 'PlanSelected',
    PayClickedCB: 'PayClickedCB',
    PayTypeShowed: 'PayTypeShowed',
    PayTypeClicked: 'PayTypeClicked',
    SuscriptionSuccesShowed: 'SuscriptionSuccesShowed',
    SuscriptionSuccesClicked: 'SuscriptionSuccesClicked',
  },
  PROFILE: {
    SettingsClicked: 'SettingsClicked',
    MySubscriptionClicked: 'MySubscriptionClicked',
    LegalWarnClicked: 'LegalWarnClicked',
    MyPointsClicked: 'MyPointsClicked',
    logOutClicked: 'logOutClicked',
    FavoriteClicked: 'FavoriteClicked',
    HelpClicked: 'HelpClicked',
    FavoriteShowed: 'FavoriteShowed',
    FAQClicked: 'FAQClicked',
    AppInfoClicked: 'AppInfoClicked',
    ContactClicked: 'ContactClicked',
    HelpShowed: 'HelpShowed',
    AccountClicked: 'AccountClicked',
  },
};

export const actions_player = Object.values(ACTIONS.PLAYER);

export const actions_page = Object.values(ACTIONS.PAGE);

export const actions_login = Object.values(ACTIONS.LOGIN);

export const actions_profile = Object.values(ACTIONS.PROFILE);

export const actions_paywall = Object.values(ACTIONS.PAYWALL);

export const SCREENNAME_LIVE = 'screen.live';
export const SCREENNAME_VOD = 'screen.vod';
export const SCREENNAME_HOME = 'screen.home';
export const SCREENNAME_LOGIN = 'screen.login';
export const SCREENNAME_PROFILE = 'screen.profile';
export const SCREENNAME_DETAIL = 'screen.detail';
export const SCREENNAME_FAVORITE = 'screen.favorite';
export const SCREENNAME_SEARCH = 'screen.search';
export const SCREENNAME_ACCOUNT = 'screen.account';
export const SCREENNAME_SUPPORT = 'screen.support';
export const SCREENNAME_CATEGORY = 'screen.category';

export const JWT_TOKEN_EXPIRED_ERROR = 'TokenExpiredError';
export const JWT_JSON_WEB_TOKEN_ERROR = 'JsonWebTokenError';
export const JWT_NOT_BEFORE_ERROR = 'NotBeforeError';

// ---------------------------------------------------
// errors messages
// ---------------------------------------------------
export const ERROR_LOGIN =
  'Ocurrió un error inesperado, intentalo de nuevo por favor.';
export const ERROR_TOKEN_EXPIRED = 'Tu sesión expiró';
export const ERROR_VIDEO_DATA =
  'Ocurrió un error inesperado. Por favor, vuelve a intentarlo';
export const ERROR_VIDEO_MEDIATOKEN_NULL =
  'No se puede visualizar el video porque no tienes una suscripción activa';
export const ERROR_TOKEN_INVALID = 'Token inválido';

export const VOD_PLAYER_ID = '6108283f025f990821c029e6';
export const LIVE_PLAYER_ID = '60c2a74e0f4668082e309af4';
