import styles from '@app/styles/components/HomeChannel24hoursCard.module.sass';
import NavItem from './KeyNavItem';

export default function HomeChannel24hoursCard({
  channel,
  title,
  img,
  chapterNumber,
  currentTime,
  progress,
  keynavup,
  keynavdown,
  keynavleft,
  keynavright,
  keynavscrolltoel,
  onClick,
}) {
  return (
    // 24hoursList
    <NavItem
      key={channel}
      keynavscrolltoel={keynavscrolltoel}
      onClick={onClick}
      keynavup={keynavup || 'disabled'}
      keynavleft={keynavleft || 'disabled'}
      keynavdown={keynavdown || 'disabled'}
      keynavright={keynavright || 'disabled'}
      className={styles.wrap}>
      <article className={styles.main}>
        <div className={styles.channel_ctn}>
          <p className={styles.channel}>{channel}</p>
        </div>
        <div>
          <div className={styles.info}>
            <img className={styles.img} src={img} alt={title} />
            <div className={styles.info_content}>
              <p className={styles.title}>{title}</p>
              <p className={styles.chapter_number}>{chapterNumber}</p>
              <p className={styles.time_left}>
                {currentTime} min. restante{currentTime !== 1 ? 's' : ''}
              </p>
              <div className={styles.progressBar_ctn}>
                <div
                  className={styles.progressBar}
                  style={{width: `${progress}%`}}></div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </NavItem>
  );
}
