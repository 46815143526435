import {imageSize} from '@app/utils/imageSize';
import {generateQuery, useGQL3} from './useGQL3';

/**
 * @typedef {object} Category
 * @property {string} title
 * @property {string} slug
 * @property {string} render
 * @property {{value: string}?} metadata
 * @property {object[]?} shows
 * @property {string} shows.title
 * @property {string} shows.slug
 * @property {string} shows.webSlug
 * @property {boolean} shows.broadcast
 * @property {{url:string}[]?} shows.images
 */

/**
 * @returns {Category[]}
 */
function parseResponse(data) {
  const categories = data?.data?.categories?.edges?.map(({node: category}) => {
    const shows = category?.shows?.edges?.map(({node: show}) => {
      return show;
    });
    return {
      title: category?.title,
      id: category?.id,
      render: category?.render,
      webSlug: category?.webSlug,
      metadata: category?.metadata,
      shows: shows,
    };
  });

  return categories;
}

/**
 * @typedef {object} getQueryPayload
 * @property {string?} imageSize
 * @property {string?} imageType
 * @property {string?} metadataType
 */

/**
 * @param {getQueryPayload} paylaod
 */
function getQuery(payload = {}) {
  return generateQuery(
    `query categoriesHome($imageType: ImageTypeEnum!, $imageSize: String!, $metadataType: MetadataTypeEnum!) {
      categories(sort: SORT_DESC) {
        edges {
          node {
            title
            id
            render
            webSlug
            metadata(metadataType: $metadataType) {
              value
            }
            shows(sort: SORT_DESC) {
              edges {
                node {
                  title
                  id
                  webSlug
                  broadcast
                  images(imageType: $imageType){
                    url(size: $imageSize)
                  }
                  metadata(metadataType: $metadataType) {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }`,
    {
      imageSize: imageSize(imageSize.type.show),
      imageType: 'LANDSCAPE',
      metadataType: 'VAST_ANDROID',
      ...payload,
    },
  );
}

/**
 * @returns {useGQL3 & {data: Category[]}}
 */
export default function useShowsByHome(payload = {}) {
  return useGQL3(parseResponse, getQuery, {
    loading: payload?.loading,
  });
}
