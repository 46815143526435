/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 */
export default function IconFastForward(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 20;
  const height = props.height || 20;

  return (
    <svg width={width} height={height} viewBox="0 0 24 26" fill="none">
      <path
        d="M2.41568 4.49004C1.08159 3.73157 0 4.35292 0 5.87677V17.1221C0 18.6475 1.08159 19.268 2.41568 18.5103L12.3324 12.8734C13.667 12.1147 13.667 10.8854 12.3324 10.1268L2.41568 4.49004Z"
        fill={fill}
      />
      <path
        d="M16.3576 5.73519C15.4238 5.18357 14.6667 5.63546 14.6667 6.74372V14.9221C14.6667 16.0315 15.4238 16.4828 16.3576 15.9317L23.2994 11.8322C24.2335 11.2804 24.2335 10.3863 23.2994 9.83463L16.3576 5.73519Z"
        fill={fill}
      />
    </svg>
  );
}
